import React, { useState } from 'react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { client } from '../../shared/client';
import { navigation, url } from '../../shared/URL';
import { Auth } from '../MainPage';
import { Link } from 'react-router-dom';
import { LoginLogo } from '../../components/Logo';
import { appHistory } from '../../index';

export const LoginForm = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const _login = ({ email, password }) => {
    setLoading(true);
    const body = queryString.stringify({
      email,
      password,
    });

    client
      .post(url.login, body, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        Auth.auth = true;
        Auth.Login = email;
        Auth.Password = password;
        appHistory.push('/');
        console.log('test');
      })
      .catch((error) => {
        if (error.response.data.code === 400) {
          setErrorMessage('Неверный логин или пароль');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <div className="authentication-page__logo">
        <LoginLogo />
      </div>
      <h1 className="authentication-page__header page__header">
        Вход в систему
      </h1>
      {loading ? (
        <Loader active />
      ) : (
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={_login}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Введите корректный email-адрес.')
              .required('Email обязателен'),
            password: Yup.string().required('Пароль обязателен'),
          })}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form
                className="authentication-page__form"
                onSubmit={handleSubmit}
                error={errorMessage !== ''}
                noValidate
              >
                <Form.Field>
                  <Form.Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email}
                    placeholder="Введите email"
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    error={errors.password}
                    placeholder="Введите пароль"
                  />
                </Form.Field>
                <Message error header="Ошибка" content={errorMessage} />
                <Button
                  type="submit"
                  className="button authentication-page__button"
                >
                  Войти
                </Button>
                <Link
                  to={navigation.requestPasswordReset}
                  className="authentication-page__recovery-link"
                >
                  Забыли пароль?
                </Link>
              </Form>
            );
          }}
        </Formik>
      )}
    </React.Fragment>
  );
};
