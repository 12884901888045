import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal, Message, Loader } from 'semantic-ui-react';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import SelectTable from '../../../components/CustomTable/SelectTable';
import { successNotify } from '../../../components/NotificationsEmitter';
import '../details-page.scss';
import './add-seller.scss';

const ChangeAdmin = (props) => {
  const { content, sellersTable, setAdminName, update } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAdminId, setSelectedAdminId] = useState<number | undefined>(
    undefined
  );
  const [currentAdminId, setCurrentAdminId] = useState<number>(0);
  const [tableContent, setTableContent] = useState<any[] | undefined>(
    undefined
  );
  const [noSellers, setNoSellers] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const _getCurrentAdminId = () => {
    return client
      .get(url.getCurrentPointAdmin + content.id)
      .then((response) => {
        setCurrentAdminId(response.data.payload.id);
      })
      .catch(() => setOpen(false));
  };

  const getFilteredTable = () =>
    sellersTable.table.content.filter((seller) => seller.id !== currentAdminId);

  const reset = () => {
    setSelectedAdminId(undefined);
  };

  const close = () => {
    _getCurrentAdminId();
    reset();
    setTimeout(() => setOpen(false), 0);
  };

  useEffect(() => {
    setLoading(true);
    _getCurrentAdminId();
    return reset;
  }, []);

  useEffect(() => {
    tableContent && setNoSellers(!tableContent.length);
    setLoading(false);
  }, [tableContent]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      _getCurrentAdminId();
      setTableContent(getFilteredTable());
    }
  }, [open]);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={close}
      trigger={
        <Button
          type="button"
          icon
          onClick={() => setOpen(true)}
          labelPosition="right"
          className="button_positive"
        >
          Изменить
          <Icon name="pencil" />
        </Button>
      }
      size="tiny"
      className="details-modal"
    >
      <Modal.Header>Выбор АРТ</Modal.Header>
      <Modal.Content>
        {loading ? (
          <Loader active inline="centered" />
        ) : noSellers ? (
          <Message error>
            <Message.Header>Ошибка</Message.Header>
            На розничной точке не назначено ни одного продавца.
          </Message>
        ) : (
          <React.Fragment>
            <Message>
              Выберите нового администратора из списка продавцов.
            </Message>
            <SelectTable
              table={sellersTable}
              data={tableContent}
              columns={sellersTableColumns}
              page={sellersTable.page}
              onPageChange={(newPage) => sellersTable.pageChange(newPage)}
              onPageSizeChange={sellersTable.onPageSizeChange}
              sortable={false}
              defaultPageSize={0}
              setSelectedValue={(value) => setSelectedAdminId(value)}
              loading={false}
              columnNameToSelect="id"
              className="details-modal__table"
            />
          </React.Fragment>
        )}
      </Modal.Content>
      <Modal.Actions>
        <ConfirmChange
          disabled={!selectedAdminId}
          newAdminId={selectedAdminId}
          sellers={sellersTable.table.content}
          content={content}
          close={() => {
            update();
            close();
          }}
          setAdminName={setAdminName}
          currentAdminId={currentAdminId}
        />
      </Modal.Actions>
    </Modal>
  );
};
export default ChangeAdmin;

const ConfirmChange = (props) => {
  const { disabled, newAdminId, currentAdminId, sellers, close, setAdminName } =
    props;
  const [open, setOpen] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!disabled) {
      let { surnameWithInitials } = sellers.find(
        (seller) => seller.id === newAdminId
      );
      setFullName(surnameWithInitials || '');
    }
    return () => {
      setFullName('');
    };
  }, [newAdminId]);

  const _setAdministrator = () => {
    setLoading(true);
    client
      .post(url.changeRetailPointAdmin + currentAdminId + '/' + newAdminId)
      .then(() => {
        successNotify(
          'Администратор назначен',
          `Продавец ${fullName} был назначен администратором розничной точки`
        );
        fullName && setAdminName(fullName);
        setOpen(false);
        close();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          type="button"
          icon
          onClick={() => setOpen(true)}
          labelPosition="right"
          className="add-seller__add-button button_positive"
          disabled={disabled}
        >
          Назначить администратора
          <Icon name="add" />
        </Button>
      }
      size="tiny"
      className="details-modal add-seller"
    >
      <Modal.Header>Подтверждение</Modal.Header>
      <Modal.Content>
        Вы действительно хотите назначить продавца {fullName} администратором?
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="button"
          icon
          onClick={() => setOpen(false)}
          labelPosition="right"
        >
          Отмена
          <Icon name="close" />
        </Button>
        <Button
          type="button"
          icon
          onClick={_setAdministrator}
          loading={loading}
          labelPosition="right"
          className="button_positive"
        >
          Назначить
          <Icon name="check" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const sellersTableColumns = [
  { Header: 'ФИО продавца', accessor: 'surnameWithInitials' },
];
