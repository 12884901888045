import React, { useState } from 'react';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import * as Yup from 'yup';
import DetailsFieldComponent from '../DetailsFieldComponent';
import { successNotify } from '../../../components/NotificationsEmitter';
import '../details-page.scss';
import './add-seller.scss';

const AddSeller = ({ retailPointId, update }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
  const [loadingAddToPoint, setLoadingAddToPoint] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [fullName, setFullName] = useState<any>({
    username: '',
    patronymic: '',
    surname: '',
  });

  const cancelChanges = () => {
    setUserId(undefined);
    setOpen(false);
  };

  const reset = () => {
    setUserId(undefined);
    setFullName({
      username: '',
      patronymic: '',
      surname: '',
    });
  };

  const _getUser = ({ phone }) => {
    reset();
    setLoadingUserData(true);
    client
      .get(url.findUserByNumber + phone)
      .then((response) => {
        setUserId(response.data.payload.id);
        setFullName({
          username: response.data.payload.username,
          patronymic: response.data.payload.patronymic,
          surname: response.data.payload.surname,
        });
      })
      .catch(() => {})
      .finally(() => setLoadingUserData(false));
  };

  const _save = (values) => {
    const { username, patronymic, surname } = values;
    setLoadingAddToPoint(true);
    // todo: no need to pass username/patronymic/surname if they weren't changed
    client
      .post(url.addSellerToPoint, {
        retailPointId,
        user: { id: userId, username, patronymic, surname },
      })
      .then(() => {
        successNotify('Успешно', 'Продавец был успешно прикреплен к точке');
        update();
        setOpen(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingAddToPoint(false);
      });
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={cancelChanges}
      trigger={
        <Button
          type="button"
          icon
          onClick={() => setOpen(true)}
          labelPosition="right"
          className="add-seller__add-button button_positive"
        >
          Добавить продавца
          <Icon name="add" />
        </Button>
      }
      size="tiny"
      className="details-modal add-seller"
    >
      <Modal.Header>Добавление продавца</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ phone: '' }}
          validationSchema={Yup.object().shape({
            phone: Yup.string().required('Обязательное поле.'),
          })}
          onSubmit={_getUser}
          validateOnChange={true}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form
                onSubmit={handleSubmit}
                noValidate
                className="add-seller__search-form"
              >
                <Form.Input
                  value={values.phone}
                  error={errors.phone}
                  fluid
                  action={{
                    icon: 'search',
                    type: 'submit',
                    loading: loadingUserData,
                    onClick: handleSubmit,
                  }}
                  onChange={handleChange}
                  placeholder="Введите номер телефона продавца"
                  name="phone"
                />
              </Form>
            );
          }}
        </Formik>
        {userId && (
          <Formik
            initialValues={{
              username: fullName.username,
              surname: fullName.surname,
              patronymic: fullName.patronymic,
            }}
            onSubmit={_save}
            validateOnChange={false}
            enableReinitialize
          >
            {({ values, errors, handleChange, handleSubmit }: any) => {
              return (
                <Form noValidate>
                  <Grid className="details-modal__grid" stackable>
                    <DetailsFieldComponent
                      value={values.surname}
                      error={errors.surname}
                      onChange={handleChange}
                      name="surname"
                      placeholder="Фамилия"
                    />
                    <DetailsFieldComponent
                      value={values.username}
                      error={errors.username}
                      onChange={handleChange}
                      name="username"
                      placeholder="Имя"
                    />
                    <DetailsFieldComponent
                      value={values.patronymic}
                      error={errors.patronymic}
                      onChange={handleChange}
                      name="patronymic"
                      placeholder="Отчество"
                    />
                    <Grid.Column width="16" className="details-modal__buttons">
                      <Button
                        type="button"
                        labelPosition="right"
                        icon
                        onClick={cancelChanges}
                      >
                        Отменить
                        <Icon name="close" />
                      </Button>
                      <Button
                        type="submit"
                        className="button_positive"
                        labelPosition="right"
                        icon
                        loading={loadingAddToPoint}
                        onClick={handleSubmit}
                      >
                        Добавить
                        <Icon name="arrow right" />
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AddSeller;
