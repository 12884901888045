import useTable from './useTable';
import { useEffect, useState } from 'react';
import { client } from '../shared/client';
import { filterURL } from '../shared/URL';

const useFetchTable = (
  requestUrl,
  filterParams?,
  isGetRequest?,
  defaultSortParams?
) => {
  const [table, setTable] = useTable();
  const [loading, setLoading] = useState<boolean>(true);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [filterRequestUrl, setFilterRequestUrl] = useState<string>(
    filterParams || ''
  );
  const [sortParams, setSortParams] = useState<any | undefined>(
    defaultSortParams
  );
  const [page, setPage] = useState<number>(0);
  const [sortAllowed, setSortAllowed] = useState<string[]>([]);

  const { offset, size, totalElements } = table;

  const _fetch = () => {
    setLoading(true);
    const filterUrl = filterRequestUrl
      ? filterURL.prefix +
        (filterRequestUrl + filterURL.separator) +
        (filterParams !== filterRequestUrl ? filterParams || '' : '')
      : '';
    const data = sortParams
      ? {
          offset,
          size: sortParams.size || size,
          sortBy: sortParams.sortBy,
          sortDirection: sortParams.sortDirection,
        }
      : { offset, size };
    client
      .request({
        method: isGetRequest ? 'get' : 'post',
        url: requestUrl + filterUrl,
        data,
      })
      .then((response): any => {
        if ('data' in response.data.payload) {
          setTable({ content: response.data.payload.data || [] });
        } else if (!response.data.payload.content) {
          setTable({ content: response.data.payload });
        } else {
          setTable(response.data.payload);
        }
        response.headers['sort-filter-by'] &&
          setSortAllowed(response.headers['sort-filter-by'].split(', '));
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(
    () => _fetch(),
    [filtered, filterRequestUrl, sortParams, offset, page, size]
  );

  useEffect(() => {
    setTable({ loading });
  }, [loading]);

  const applyFilters = (url) => {
    setFilterRequestUrl(encodeURI(url));
    setFiltered(true);
    pageChange(0);
  };

  const sort = (newSort) => {
    sortAllowed.includes(newSort[0].id) &&
      setSortParams({
        sortBy: newSort[0].id,
        sortDirection: newSort[0].desc ? 'desc' : 'asc',
      });
  };

  const pageChange = (page) => {
    setPage(page);
    setTable({ offset: page });
  };

  const onPageSizeChange = (size) => setTable({ size });

  return {
    table,
    loading,
    page,
    _fetch,
    _apply: applyFilters,
    _sort: sort,
    pageChange,
    onPageSizeChange,
    totalElements,
  };
};

export default useFetchTable;
