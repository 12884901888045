import React, { useState } from 'react';
import DetailsFieldComponent from '../DetailsFieldComponent';
import { Button, Form, Grid, Modal, Tab, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { DateTimeCell, StatusCell } from '../../../components/CustomCell';
import BlockButtonComponent from '../../../components/BlockButtonComponent';
import { successNotify } from '../../../components/NotificationsEmitter';
import SearchPointComponent from '../../../components/SearchPointComponent';
import CardImages from './components/CardImages';
import useFetchTable from '../../../hooks/useFetchTable';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import { formatDate, userStatusInnerText } from '../../../shared/utils';
import {
  RUSSIAN_PHONE_REGEX,
  validationErrorMessages,
  taxStatusOptions,
  userStatusOptions,
  saleStatusOptions,
  userRoleOptions,
} from '../../../shared/constants';
import 'react-datepicker/dist/react-datepicker.css';
import '../details-page.scss';

const SellerTables = ({ id, seller }) => {
  const instrumentsTable = useFetchTable(url.getToolByBuyerId + id);
  const salesTable = useFetchTable(url.getSellerHistoryById + id);

  const instrumentsTableColumns = [
    { Header: 'Наименование инструмента', accessor: 'modelName' },
    {
      Header: 'Дата покупки',
      accessor: 'productionDate',
      Cell: (row) => DateTimeCell(row),
    },
  ];

  const salesColumns = [
    {
      Header: 'Наименование инструмента',
      accessor: 'tool.model.name',
      id: 'name',
    },
    {
      Header: 'Дата покупки',
      accessor: 'saleDate',
      Cell: (row) => DateTimeCell(row),
    },
    {
      Header: 'Статус покупки',
      accessor: 'saleStatus',
      Cell: (row) => StatusCell(row, saleStatusOptions),
    },
  ];

  const panes = [
    {
      menuItem: 'Свои инструменты',
      render: () => (
        <CustomTable
          table={instrumentsTable}
          columns={instrumentsTableColumns}
          sortable={false}
          defaultPageSize={0}
          className="details-modal__table"
        />
      ),
    },
    seller
      ? {
          menuItem: 'Продажи',
          render: () => (
            <CustomTable
              table={salesTable}
              columns={salesColumns}
              //handle pagination without backend
              sortable={false}
              resolveData={(data) => data.saleHistory || []}
              defaultPageSize={0}
              className="details-modal__table"
            />
          ),
        }
      : {},
  ];

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      className="details-modal__tabs"
    />
  );
};

const UserCard = ({ content, update, close }) => {
  const [open] = useState<boolean>(true);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const seller = content.status === 'SELLER';
  const passportImages: Array<object> =
    (content.passport && content.passport.passportImages) || [];

  const _resetPassword = () => {
    setResetLoading(true);
    client
      .post(url.resetUserPassword + content.id)
      .then(() => {
        successNotify('Сброс пароля', 'Пароль успешно сброшен');
      })
      .catch(() => {})
      .finally(() => setResetLoading(false));
  };

  const _editUser = (values) => {
    setEditLoading(true);
    const userData = {
      ...values,
      birthday: formatDate(values.birthday),
      isPatronymic: Boolean(values.patronymic),
    };
    const legalUserData = {
      ...values,
      birthday: formatDate(values.birthday),
      responsible: `${values.surname} ${values.username} ${values.patronymic}`,
    };
    const editUrl = content.legal ? url.editLegalUser : url.editUser;
    const data = content.legal ? legalUserData : userData;

    client
      .post(`${editUrl}${values.id}`, data)
      .then(() => {
        successNotify(
          'Редактирование пользователя',
          'Пользователь успешно отредактирован'
        );
      })
      .catch(() => {})
      .finally(() => {
        update();
        setEditLoading(false);
      });
  };

  const getInitialValues = () => {
    if (content.legal) {
      return {
        id: content.id,
        surnameWithInitials: content.surnameWithInitials || '',
        username: content.username || '',
        surname: content.surname || '',
        patronymic: content.patronymic || '',
        birthday: content.birthday,
        passport: {
          passportSeries:
            (content.passport && content.passport.passportSeries) || '',
        },
        mobile: content.mobile || '',
        inn: content.inn || '',
        organization_inn: content.organization_inn || '',
        taxStatus: content.taxStatus || '',
        locked: content.locked,
        taxLocked: content.taxLocked,
        chargedBonus: content.chargedBonus,
        dischargedBonus: content.dischargedBonus,
        roles: content.roles && content.roles.join(','),
        status: content.status,
        pointInn: content.pointInn || '',
        address: content.address || '',
        salesAmount: content.salesAmount,
      };
    } else {
      return {
        id: content.id,
        surnameWithInitials: content.surnameWithInitials || '',
        username: content.username || '',
        surname: content.surname || '',
        patronymic: content.patronymic || '',
        birthday: content.birthday,
        passport: {
          passportSeries:
            (content.passport && content.passport.passportSeries) || '',
        },
        mobile: content.mobile || '',
        inn: content.inn || '',
        taxStatus: content.taxStatus || '',
        locked: content.locked,
        taxLocked: content.taxLocked,
        chargedBonus: content.chargedBonus,
        dischargedBonus: content.dischargedBonus,
        roles: content.roles && content.roles.join(','),
        status: content.status,
        pointInn: content.pointInn || '',
        address: content.address || '',
        salesAmount: content.salesAmount,
      };
    }
  };

  // console.log(content);

  return (
    <Modal
      open={open}
      onClose={() => {
        close();
      }}
      closeIcon
      className="details-modal"
    >
      <Modal.Header>
        {content.legal
          ? 'Карточка юридического пользователя'
          : 'Карточка пользователя'}
      </Modal.Header>
      {
        <React.Fragment>
          <Modal.Content className="details-modal__content">
            <Formik
              initialValues={getInitialValues()}
              onSubmit={_editUser}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                username: Yup.string().required(
                  validationErrorMessages.required
                ),
                surname: Yup.string().required(
                  validationErrorMessages.required
                ),
                mobile: Yup.string().matches(RUSSIAN_PHONE_REGEX, {
                  message: validationErrorMessages.mobileFormat,
                }),
              })}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }: any) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Grid className="details-modal__grid" stackable>
                      <DetailsFieldComponent
                        value={values.surnameWithInitials}
                        error={errors.surnameWithInitials}
                        onChange={handleChange}
                        name="surnameWithInitials"
                        placeholder="ФИО пользователя"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.surname}
                        error={errors.surname}
                        onChange={handleChange}
                        name="surname"
                        placeholder="Фамилия"
                      />
                      <DetailsFieldComponent
                        value={values.username}
                        error={errors.username}
                        onChange={handleChange}
                        name="username"
                        placeholder="Имя"
                      />
                      <DetailsFieldComponent
                        value={values.patronymic}
                        error={errors.patronymic}
                        onChange={handleChange}
                        name="patronymic"
                        placeholder="Отчество"
                      />
                      <DetailsFieldComponent
                        value={values.birthday}
                        error={errors.birthday}
                        onChange={handleChange}
                        name="birthday"
                        placeholder="Дата рождения"
                        renderCustomInput={() => (
                          <DatePicker
                            selected={
                              values.birthday && new Date(values.birthday)
                            }
                            onChange={(date) => setFieldValue('birthday', date)}
                            dateFormat="dd/MM/yy"
                            placeholderText="Дата рождения"
                          />
                        )}
                      />
                      <DetailsFieldComponent
                        value={
                          values.passport && values.passport.passportSeries
                        }
                        error={
                          errors.passport && errors.passport.passportSeries
                        }
                        onChange={handleChange}
                        name="passport.passportSeries"
                        placeholder="Серия/номер паспорта"
                      />
                      <DetailsFieldComponent
                        value={values.mobile}
                        error={errors.mobile}
                        onChange={handleChange}
                        name="mobile"
                        placeholder="Номер телефона"
                      />
                      {!content.legal && (
                        <DetailsFieldComponent
                          value={values.inn}
                          placeholder="ИНН пользователя"
                          disabled
                        />
                      )}
                      {content.legal && (
                        <DetailsFieldComponent
                          value={values.organization_inn}
                          placeholder="ИНН организации"
                          disabled
                        />
                      )}
                      <DetailsFieldComponent
                        value={
                          values.taxStatus &&
                          userStatusInnerText(
                            values.taxStatus,
                            taxStatusOptions
                          )
                        }
                        placeholder="Статус ФНС"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={
                          values.status &&
                          userStatusInnerText(values.status, userStatusOptions)
                        }
                        placeholder="Статус пользователя"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.locked}
                        error={errors.locked}
                        onChange={setFieldValue}
                        name="locked"
                        placeholder="Блокировка пользователя"
                        renderCustomInput={() =>
                          BlockButtonComponent({
                            id: content.id,
                            blockURL: url.blockUser,
                            unblockURL: url.unblockUser,
                            value: values.locked,
                            update,
                          })
                        }
                      />
                      <DetailsFieldComponent
                        value={values.taxLocked}
                        error={errors.taxLocked}
                        onChange={setFieldValue}
                        name="taxLocked"
                        placeholder="Блокировка вывода бонусов"
                        renderCustomInput={() =>
                          BlockButtonComponent({
                            id: content.id,
                            blockURL: url.taxBlockUser,
                            unblockURL: url.taxUnblockUser,
                            value: values.taxLocked,
                            update,
                          })
                        }
                      />
                      <DetailsFieldComponent
                        value={values.chargedBonus}
                        error={errors.chargedBonus}
                        onChange={handleChange}
                        name="chargedBonus"
                        placeholder="Количество начисленных бонусов"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.dischargedBonus}
                        error={errors.dischargedBonus}
                        onChange={handleChange}
                        name="dischargedBonus"
                        placeholder="Количество выведенных бонусов"
                        disabled
                      />
                      {seller && (
                        <React.Fragment>
                          <h3>Продавец</h3>
                          <DetailsFieldComponent
                            value={
                              values.roles &&
                              userStatusInnerText(values.roles, userRoleOptions)
                            }
                            placeholder="Роль продавца"
                            disabled
                          />
                          <DetailsFieldComponent
                            value={values.pointInn}
                            error={errors.pointInn}
                            onChange={handleChange}
                            name="pointInn"
                            placeholder="ИНН организации"
                            disabled
                          />
                          <DetailsFieldComponent
                            value={values.address}
                            error={errors.address}
                            onChange={handleChange}
                            name="address"
                            placeholder="Адрес розничной точки"
                            disabled
                          />
                          <DetailsFieldComponent
                            placeholder="Розничная точка"
                            renderCustomInput={() =>
                              SearchPointComponent({
                                setPointId: (value) =>
                                  setFieldValue('pointId', value),
                              })
                            }
                          />
                          <DetailsFieldComponent
                            value={values.salesAmount}
                            error={errors.salesAmount}
                            onChange={handleChange}
                            name="salesAmount"
                            placeholder="Количество продаж"
                            disabled
                          />
                        </React.Fragment>
                      )}
                      <SellerTables id={content.id} seller={seller} />
                      <CardImages
                        passportImages={passportImages.reverse()}
                        loading={passportImages.length > 0}
                      />
                      <Grid.Column
                        width="16"
                        className="details-modal__buttons"
                      >
                        <Button
                          type="button"
                          loading={resetLoading}
                          onClick={_resetPassword}
                        >
                          Сбросить пароль
                        </Button>
                        <Button
                          type="submit"
                          className="button_positive"
                          labelPosition="right"
                          onClick={handleSubmit}
                          loading={editLoading}
                          icon
                        >
                          Сохранить изменения
                          <Icon name="arrow right" />
                        </Button>
                      </Grid.Column>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Content>
        </React.Fragment>
      }
    </Modal>
  );
};

export default UserCard;
