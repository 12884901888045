import React, { useEffect, useRef, useState } from 'react';
import { Image, Modal } from 'semantic-ui-react';
import { formatTimeMessage } from '../../../shared/utils';
import { client, url } from '../../../shared';

const MessageFile = ({ message }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {message.file && (
        <Modal
          centered={true}
          closeIcon
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <div className="chat__field__image-trigger">
              {message.file && (
                <img
                  src={`/web/${url.files}${message.file}`}
                  alt="Файл не найден"
                />
              )}
            </div>
          }
        >
          {message.file && (
            <Modal.Content image>
              <Image
                centered
                size="large"
                src={`/web/${url.files}${message.file}`}
              />
            </Modal.Content>
          )}
        </Modal>
      )}
    </>
  );
};

const Messages = ({ messages, chatId, scrollTop }) => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    setScrollHeight(messagesRef.current?.scrollHeight!);
    if (messages && messages.length > 10) {
      return messagesRef.current?.scrollTo(
        0,
        messagesRef.current?.scrollHeight - scrollHeight
      );
    }
    if (messagesRef.current) messagesRef.current.scrollTo(0, 999999);
  }, [messages, chatId]);

  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop === 0) {
      scrollTop();
    }
  };

  return (
    <div
      className="chat__field__messages"
      style={
        messages && chatId
          ? { height: 'calc(100% - 83px)' }
          : { height: 'calc(100% - 41px)' }
      }
      ref={messagesRef}
      onScroll={handleScroll}
    >
      {messages && chatId ? (
        messages.map((message, index) => {
          message.isRead = true;
          return (
            <div
              key={index}
              className={` ${
                message.role === 'USER' ? 'user-message' : 'admin-message'
              }`}
            >
              <div
                className={`chat__field__message ${
                  message.role === 'USER'
                    ? 'user-message--color'
                    : 'admin-message--color'
                }`}
              >
                <MessageFile message={message} key={index} />
                {message.message}
              </div>
              <div className="chat__field__time">
                {formatTimeMessage(message.created)}
              </div>
            </div>
          );
        })
      ) : (
        <div className="chat__field__messages__no-messages">
          <h2>Выберете диалог</h2>
        </div>
      )}
    </div>
  );
};

export default Messages;
