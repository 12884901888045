import React, { useState } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import TableStats from '../../components/TableStats';
import FiltersComponent from '../../components/FiltersComponent';
import { RangeDateInput } from '../../components/FiltersComponent/FilterField';
import CustomTable from '../../components/CustomTable/CustomTable';
import { DateTimeCell, StatusCell } from '../../components/CustomCell';
import useFetchTable from '../../hooks/useFetchTable';
import { filterURL, url } from '../../shared/URL';
import { saleStatusOptions } from '../../shared/constants';
import LegalSaleCard from '../Cards/LegalSaleCard/LegalSaleCard';

const ConfirmLegalSalePage = () => {
  interface ISortParams {
    sortBy: string;
    sortDirection: 'desc' | 'asc';
  }

  const [sortParams, _setSortParams] = useState<ISortParams | undefined>({
    sortBy: 'createdTime',
    sortDirection: 'desc',
  });

  const columns = [
    { Header: 'Наименование инструмента', accessor: 'modelName' },
    { Header: 'Серийный номер', accessor: 'sn' },
    {
      Header: 'Статус продажи',
      accessor: 'saleStatus',
      Cell: (row) => StatusCell(row, saleStatusOptions),
    },
    {
      Header: 'Дата продажи',
      accessor: 'createdTime',
      Cell: (row) => DateTimeCell(row),
    },
    { Header: 'ИНН организации', accessor: 'retailInn' },
    { Header: 'Цена из накладной', accessor: 'invoice.sum' },
  ];

  const fetchTable = useFetchTable(
    url.getSales,
    `saleStatus$APPROVE_PENDING;legal$true${filterURL.isNotRejected}`,
    undefined,
    sortParams
  );

  return (
    <div className="page table-page">
      <h1 className="page__header">Подтверждение продаж</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Input
                type="text"
                name="modelName"
                placeholder="Наименование инструмента"
              />
              <Input type="text" name="sn" placeholder="Серийный номер" />
              <Input
                type="text"
                name="retailInn"
                placeholder="ИНН организации"
              />
              <Input
                type="text"
                name="sellerFullName"
                placeholder="ФИО продавца"
              />
              <Input type="number" name="mobile" placeholder="Номер телефона" />
              <RangeDateInput name="createdTime" placeholder="Дата продажи" />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={12}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={LegalSaleCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ConfirmLegalSalePage;
