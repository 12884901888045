import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'semantic-ui-react';
import { client } from '../../../../../shared/client';
import { url } from '../../../../../shared/URL';
import '../../InvoiceCard.scss';
import pdf from '../../../../../icons/pdf.svg';
interface ICard {
  item: string;
}

const Card = ({ item }: ICard) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [enlargedModalOpen, setEnlargedModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
    setEnlargedModalOpen(true);
  };

  const handleImageClose = () => {
    setEnlargedImage(null);
    setEnlargedModalOpen(false);
  };

  // const getName = (filename: string) => {
  //   const parts = filename.split('-');
  //   const result = parts.pop();
  //   return result;
  // };

  const getName = (filename: string) => {
    const parts = item.split('-');
    if (parts.length > 1) {
      const res = parts.slice(5).join('-');
      return res;
    } else {
      return filename;
    }
  };

  const handleDownload = (imageUrl: string | undefined, item) => {
    if (!imageUrl) {
      return;
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = imageUrl;
      downloadLink.download = getName(item) as string;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const getImage = (imageUrl) => {
    client
      .get(`${url.files}${imageUrl}`, { responseType: 'arraybuffer' })
      .then((res) => {
        const fileType = imageUrl.substring(imageUrl.lastIndexOf('.') + 1);
        if (fileType === 'pdf') {
          setImage(
            `data:application/pdf;base64,${Buffer.from(
              res.data,
              'binary'
            ).toString('base64')}`
          );
        } else if (['png', 'jpg', 'jpeg', 'jfif'].includes(fileType)) {
          setImage(
            `data:image/${fileType};base64,${Buffer.from(
              res.data,
              'binary'
            ).toString('base64')}`
          );
        } else {
          console.error('Неизвестный тип файла:', fileType);
          setImage('');
        }
        setError(false);
      })
      .catch(() => {
        setImage('');
        setError(true);
      });
  };

  useEffect(() => {
    getImage(item);
  }, [item]);

  return (
    <>
      <div className="invoice">
        {item.endsWith('.pdf') ? (
          <Image src={pdf} className="invoice-img" />
        ) : (
          <>
            {image ? (
              <Image
                src={image}
                bordered
                className="invoice-img cursor"
                onClick={() => handleImageClick(image)}
              />
            ) : (
              <div className="error-message">Ошибка</div>
            )}
          </>
        )}
        <div className="invoice-info">
          <h3>{getName(item)}</h3>
          <Button
            size="small"
            onClick={() => handleDownload(image, item)}
            disabled={error}
          >
            Скачать
          </Button>
        </div>
      </div>

      <Modal
        open={enlargedModalOpen}
        onClose={handleImageClose}
        className="details-modal"
      >
        <Modal.Content className="details-modal__content">
          <div className="img-modal">
            {enlargedImage && (
              <Image src={enlargedImage} centered size="large" />
            )}
            <Button className="img-modal-btn" onClick={handleImageClose}>
              Ок
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Card;
