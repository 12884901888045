import React, { useState } from 'react';
import { url, filterURL } from '../../../shared/URL';
import { Button, Form, Grid, Icon, Input, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RangeInput } from '../../../components/FiltersComponent/FilterField';
import FiltersComponent from '../../../components/FiltersComponent';
import CustomTable from '../../../components/CustomTable/CustomTable';
import TableStats from '../../../components/TableStats';
import useFetchTable from '../../../hooks/useFetchTable';
import FileUploadButton from '../../../components/FileUploadButton';
import ModelCard from '../../Cards/ModelCard';
import { successNotify } from '../../../components/NotificationsEmitter';
import { validationErrorMessages } from '../../../shared/constants';
import { client } from '../../../shared/client';

const ChangeBonusesModal = (totalElements, filters, update) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const _requestBonusesUpdate = (values) => {
    setLoading(true);
    client
      .post(url.changeBonuses + filterURL.prefix + filters, {
        buyerBonus: values.buyerBonus,
        sellerBonus: values.sellerBonus,
      })
      .then(() => {
        setOpen(false);
        successNotify('Успешно', 'Баллы были успешно обновлены');
        update();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Modal
        closeIcon
        trigger={
          <Button
            className="filters__button filters__modal-trigger"
            labelPosition="right"
            icon
          >
            Обновить баллы
            <Icon name="arrow right" />
          </Button>
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size="mini"
        className="modal"
      >
        <Modal.Content>
          <Formik
            initialValues={{ sellerBonus: '', buyerBonus: '' }}
            onSubmit={_requestBonusesUpdate}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              sellerBonus: Yup.number().min(
                0,
                validationErrorMessages.numberCantBeNegative
              ),
              buyerBonus: Yup.number().min(
                0,
                validationErrorMessages.numberCantBeNegative
              ),
            })}
          >
            {({ errors, handleChange, handleSubmit }: any) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <Form.Input
                    type="number"
                    placeholder="Бонусы для продавца"
                    className="modal__input"
                    name="sellerBonus"
                    onChange={handleChange}
                    error={errors.sellerBonus}
                  />
                  <Form.Input
                    type="number"
                    placeholder="Бонусы для покупателя"
                    className="modal__input"
                    name="buyerBonus"
                    onChange={handleChange}
                    error={errors.buyerBonus}
                  />
                  <Button type="submit" loading={loading}>
                    Применить к {totalElements} запис
                    {totalElements < 2 ? 'и' : 'ям'}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal>
      <FileUploadButton url={url.importModelToDB} update={update} />
      <FileUploadButton
        url={url.updateModelToDB}
        buttonName={'Обновить данные'}
        update={update}
      />
    </React.Fragment>
  );
};

const ModelsPage = () => {
  const fetchTable = useFetchTable(url.getModels);

  const columns = [
    { Header: 'Наименование инструмента', accessor: 'name' },
    { Header: 'Артикул', accessor: 'vendorCode' },
    { Header: 'РРЦ', accessor: 'msrp' },
    { Header: 'Бонусы для продавца', accessor: 'sellerBonus' },
    {
      Header: 'Бонусы для юр. лица',
      accessor: 'sellerLegalBonus',
      Cell: ({ value }) => (value == null ? 0 : value),
    },
    { Header: 'Бонусы для покупателя', accessor: 'buyerBonus' },
    { Header: 'Продажи', accessor: 'sold' },
    { Header: '% отклонения', accessor: 'deviationPercent' },
  ];

  return (
    <div className="page table-page">
      <h1 className="page__header">Каталог моделей инструментов</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
              render={ChangeBonusesModal}
              update={fetchTable._fetch}
            >
              <Input
                type="text"
                name="name"
                placeholder="Наименование инструмента"
              />
              <Input type="text" name="vendorCode" placeholder="Артикул" />
              <RangeInput type="range" name="msrp" placeholder="РРЦ" />
              <RangeInput
                type="range"
                name="sellerBonus"
                placeholder="Бонусы для продавца"
              />
              <RangeInput
                type="range"
                name="buyerBonus"
                placeholder="Бонусы для покупателя"
              />
              <RangeInput type="range" name="sold" placeholder="Продажи" />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={ModelCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ModelsPage;
