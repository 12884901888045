import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Search, SearchProps } from 'semantic-ui-react';
import { client } from '../../shared/client';
import { url } from '../../shared/URL';
import { map, debounce } from 'lodash';
import { AxiosResponse } from 'axios';

import './search-point.scss';

const SearchPointComponent = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchProps>([]);
  const [value, setValue] = useState<string | undefined>(undefined);

  const noResultsMessage = 'Ничего не найдено.';
  const placeholder = 'Введите ИНН или название точки';

  useEffect((): void => {
    Boolean(value) && _search();
  }, [value]);

  const _search = (): void => {
    setLoading(true);
    client
      .post(`${url.findPointAddress}`, value, {
        headers: { 'Content-Type': 'text/plain' },
      })
      .then(({ data }: AxiosResponse): void => {
        setResults(transformResults(data.payload));
      })
      .catch((): void => {})
      .finally((): void => setLoading(false));
  };

  const transformResults = (results: {
    [key: string]: string;
  }): SearchProps => {
    return map(results, (content: string, key: string) => ({
      title: content,
      pointId: key,
    }));
  };

  const handleSearchChange = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setValue(data.value);
  };

  const handleResultSelected = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setValue(data.result.title);
    props.setPointId(data.result.pointId);
  };

  return (
    <Search
      loading={loading}
      onResultSelect={handleResultSelected}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
      noResultsMessage={noResultsMessage}
      results={results}
      value={value}
      placeholder={placeholder}
      className="search-point"
      fluid
    />
  );
};

export default SearchPointComponent;
