import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { client } from '../shared/client';
import { successNotify } from './NotificationsEmitter';

const BlockButtonComponent = (props) => {
  const { value, id, blockURL, unblockURL, update } = props;
  const [blocked, setBlocked] = useState<boolean>(value);
  const [loading, setLoading] = useState<boolean>(false);

  const _block = () => {
    setLoading(true);
    const url = blocked ? unblockURL : blockURL;
    client
      .post(url + id)
      .then(() => {
        successNotify(
          'Успешно',
          `${blocked ? 'Разблокировка' : 'Блокировка'} прошла успешно`
        );
        setBlocked(!blocked);
        update && update();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <Button
      type="button"
      labelPosition="right"
      loading={loading}
      onClick={_block}
      icon
    >
      {blocked ? 'Разблокировать' : 'Заблокировать'}
      <Icon name="ban" />
    </Button>
  );
};

export default BlockButtonComponent;
