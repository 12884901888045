import React, { useEffect } from 'react';
import { Checkbox, Grid, Input, Select } from 'semantic-ui-react';
import useFetchTable from '../../../hooks/useFetchTable';
import { BooleanCell, StatusCell } from '../../../components/CustomCell';
import SendNotificationModal from '../../../components/SendNotificationModal';
import FiltersComponent from '../../../components/FiltersComponent';
import CustomTable from '../../../components/CustomTable/CustomTable';
import TableStats from '../../../components/TableStats';
import UserCard from '../../Cards/UserCard';
import { filterURL, url } from '../../../shared/URL';
import { taxStatusOptions } from '../../../shared/constants';

const BuyersPage = () => {
  const fetchTable = useFetchTable(url.getUsers, filterURL.buyers);

  useEffect(() => {
    if (fetchTable.table.content) {
      const updatedContent = fetchTable.table.content.map((user) => {
        if (user.legal && user.responsible) {
          const [surname, name, patronymic] = user.responsible.split(' ');
          return {
            ...user,
            surname: surname || '',
            username: name || '',
            patronymic: patronymic || '',
            surnameWithInitials: user.responsible || '',
          };
        }
        return user;
      });

      fetchTable.table.content = updatedContent;
    }
  }, [fetchTable.table.content]);

  return (
    <div className="page table-page">
      <h1 className="page__header">Список покупателей</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
              render={SendNotificationModal}
              update={fetchTable._fetch}
            >
              <Input
                type="text"
                name="surnameWithInitials"
                placeholder="ФИО пользователя"
              />
              <Input type="number" name="mobile" placeholder="Номер телефона" />
              <Select
                placeholder="Статус ФНС"
                options={taxStatusOptions}
                name="taxStatus"
                clearable
                multiple
              />
              <Checkbox name="legal" label="Юридическое лицо" />
              <Checkbox name="locked" label="Пользователь заблокирован" />
              <Checkbox name="taxLocked" label="Вывод бонусов заблокирован" />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={UserCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default BuyersPage;

const columns = [
  { Header: 'ФИО пользователя', accessor: 'surnameWithInitials' },
  { Header: 'Номер телефона', accessor: 'mobile' },
  {
    Header: 'Статус ФНС',
    accessor: 'taxStatus',
    Cell: (row) => StatusCell(row, taxStatusOptions),
  },
  {
    Header: 'Блокировка пользователя',
    accessor: 'locked',
    Cell: (row) => BooleanCell(row),
  },
  {
    Header: 'Блокировка вывода бонусов',
    accessor: 'taxLocked',
    Cell: (row) => BooleanCell(row),
  },
  { Header: 'Начисленные бонусы', accessor: 'chargedBonus' },
  { Header: 'Выведенные бонусы', accessor: 'dischargedBonus' },
];
