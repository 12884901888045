import React from 'react';
import { navigation } from '../../shared/URL';
import { Route, Switch } from 'react-router';
import { LoginForm } from './LoginForm';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';
import { PasswordRecoveryComponent } from './PasswordRecoveryComponent';
import './authentication-page.scss';

const AuthenticationPage = () => {
  return (
    <div className="authentication-page">
      <Switch>
        <Route
          exact
          path={navigation.requestPasswordReset}
          component={PasswordRecoveryComponent}
        />
        <Route
          exact
          path={navigation.resetPassword}
          component={PasswordRecoveryForm}
        />
        <Route path="/" component={LoginForm} />
      </Switch>
    </div>
  );
};

export default AuthenticationPage;
