import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { autorun, observable } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { navigation } from '../../shared/URL';
import ModelsPage from '../Tables/ModelsPage';
import ToolsPage from '../Tables/ToolsPage';
import Navigation from '../../components/Navigation/Navigation';
import SellersPage from '../Tables/UsersPage/SellersPage';
import BuyersPage from '../Tables/UsersPage/BuyersPage';
import RetailPointsPage from '../Tables/RetailPointsPage';
import SalesPage from '../Tables/SalesPage';
import ReceiptsPage from '../Tables/ReceiptsPage';
import PaymentsPage from '../Tables/PaymentsPage';
import UsersPage from '../Tables/UsersPage';
import NotConfirmedRetailPoints from '../Tables/confirmPage/NotConfirmedRetailPoints';
import NotConfirmedUsers from '../Tables/confirmPage/NotConfirmedUsers';
import NotConfirmedSales from '../Tables/confirmPage/NotConfirmedSales';
import AntiFraudOffenders from '../Tables/AntiFraudPage/AntiFraudOffenders';
import AntiFraudSettings from '../Tables/AntiFraudPage/AntiFraudSettings';
import AdminsPage from '../Tables/AdminsPage';
import ReportsPage from '../ReportsPage';
import ActiveDialogs from '../SupportPage/ActiveDialogs';
import CompletedDialogs from '../SupportPage/CompletedDialogs';
import './main-page.scss';
import NotConfirmedUnknownTools from '../Tables/confirmPage/NotConfirmedUnknownTools';
import ConfirmLegalToolPage from '../ConfirmLegalToolPage/ConfirmLegalToolPage';
import InvoicesPage from '../InvoicesPage/InvoicesPage';
import ConfirmLegalSalePage from '../ConfirmLegalSalePage/ConfirmLegalSalePage';

export const Auth = observable<{ auth: boolean; Login: any; Password: any }>({
  auth: !!(
    localStorage.getItem('logged') && localStorage.getItem('logged') === 'true'
  ),
  Login: localStorage.getItem('Login') && localStorage.getItem('Login'),
  Password:
    localStorage.getItem('Password') && localStorage.getItem('Password'),
});

autorun(() => {
  localStorage.setItem('logged', Auth.auth.toString());
  localStorage.setItem('Login', Auth.Login.toString());
  localStorage.setItem('Password', Auth.Password.toString());
});

const MainPage = () => {
  const logged = useObserver(() => Auth.auth);

  return logged ? (
    <React.Fragment>
      <Navigation />
      <div className="main-page">
        <Switch>
          <Route path={navigation.models} component={ModelsPage} />
          <Route path={navigation.tools} component={ToolsPage} />
          <Route path={navigation.users} component={UsersPage} />
          <Route
            path={navigation.notConfirmedUsers}
            component={NotConfirmedUsers}
          />
          <Route path={navigation.sellers} component={SellersPage} />
          <Route path={navigation.buyers} component={BuyersPage} />
          <Route path={navigation.retailPoints} component={RetailPointsPage} />
          <Route
            path={navigation.confirmLegalTool}
            component={ConfirmLegalToolPage}
          />
          <Route
            path={navigation.confirmLegalSale}
            component={ConfirmLegalSalePage}
          />
          <Route
            path={navigation.notConfirmedRP}
            component={NotConfirmedRetailPoints}
          />
          <Route path={navigation.invoices} component={InvoicesPage} />
          <Route path={navigation.receipts} component={ReceiptsPage} />
          <Route path={navigation.payments} component={PaymentsPage} />
          <Route path={navigation.sales} component={SalesPage} />
          <Route
            path={navigation.notConfirmedSales}
            component={NotConfirmedSales}
          />
          <Route
            path={navigation.notConfirmedSalesUnknownTools}
            component={NotConfirmedUnknownTools}
          />
          <Route path={navigation.admins} component={AdminsPage} />
          <Route path={navigation.reports} component={ReportsPage} />
          <Route path={navigation.activeDialogs} component={ActiveDialogs} />
          <Route
            path={navigation.completedDialogs}
            component={CompletedDialogs}
          />
          <Route
            path={navigation.antiFraudOffenders}
            component={AntiFraudOffenders}
          />
          <Route
            path={navigation.antiFraudSettings}
            component={AntiFraudSettings}
          />
          <Route path="/" component={SalesPage} />
        </Switch>
      </div>
    </React.Fragment>
  ) : (
    <Redirect to={navigation.auth} />
  );
};

export default MainPage;
