import React, { useState, useEffect } from 'react';
import { Auth } from '../MainPage';
import Dialogs from './Dialogs';
import Chat from './Chat/Chat';
import UserInfo from './UserInfo';
import { client } from '../../shared';
import { url } from '../../shared';
import { taxStatusOptions, userStatusOptions } from '../../shared/constants';
import { findChat, userStatusInnerText } from '../../shared/utils';
import { connect } from '../../shared/ws';
import './active-dialogs.scss';
import { debounce, uniqBy } from 'lodash';

const DialogsHeader = (props) => {
  const { column1, column2 } = props;
  return (
    <div className="active-dialogs__header">
      <div>{column1.name}</div>
      {column2 && <div>{column2.name}</div>}
    </div>
  );
};

const ActiveDialogs = () => {
  const [count, forceUpdate] = useState<number>(0);
  const [chatId, setChatId] = useState<number | null>(null);
  const [chats, setChats] = useState<Record<string, any>[]>();
  const myContent: Array<object> = [];
  const activeContent: Array<object> = [];
  const processContent: Array<object> = [];
  const [incomingMessages, setIncomingMessages] = useState([]);
  const [chatContent, setChatContent] = useState<Record<string, any> | null>(
    null
  );
  const [offset, setOffset] = useState(0);
  const [contentCount, setContentCount] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const fetchChats = async (offset: number = 0, update = 0) => {
    if (contentCount && update !== 99) return;
    setDialogLoading(true);
    await Promise.all([
      client.post(`${url.chatsActive2}`, {
        offset: offset,
        size: 30,
      }),
      client.post(`${url.chatsProcess2}`, {
        offset: offset,
        size: 30,
      }),
    ])
      .then((res) => {
        if (
          !res[0].data.payload.content.length &&
          !res[1].data.payload.content.length
        ) {
          return setContentCount(true);
        }

        if (!chats?.length || update === 99) {
          return setChats(
            res[0].data.payload.content.concat(res[1].data.payload.content)
          );
        }
        const resultContent = uniqBy(
          chats?.concat(
            res[0].data.payload.content.concat(res[1].data.payload.content)
          ),
          'id'
        );
        setChats(resultContent);
      })
      .finally(() => setDialogLoading(false));
  };

  useEffect(() => {
    fetchChats(offset, count);
  }, [count, incomingMessages]);
  useEffect(() => {
    connect(incomingMessages, setIncomingMessages, Auth.Login, Auth.Password);
  }, []);

  // const chatContent = chats && findChat(chats, chatId);

  const sortContentByType = () => {
    if (chats) {
      for (let chat of chats as any) {
        switch (chat && chat.chatStatus) {
          case 'ACTIVE':
            activeContent.push(chat);
            break;
          case 'PROCESS':
            chat.admin && chat.admin.email === Auth.Login
              ? myContent.push(chat)
              : processContent.push(chat);
            break;
        }
      }
    }
  };
  sortContentByType();

  const onChatClick = (chat) => {
    setChatId(chat.id);
    client
      .post(`${url.chatsMessages2}/${chat.id}`, {
        offset: 0,
        size: 10,
      })
      .then(({ data }) => {
        chat.messages = data.payload;
        setChatContent(chat);
      });
  };

  const dialogScrollTop = debounce(() => {
    fetchChats(offset + 1);
    setOffset(offset + 1);
  }, 350);

  return (
    <div className="page table-page">
      <h1 className="page__header">Активные диалоги</h1>
      <div className="active-dialogs">
        <div className="active-dialogs__category">
          <DialogsHeader
            column1={{ name: 'Диалоги:' }}
            column2={{ name: 'Сообщения:' }}
          />
          <Dialogs
            header={'Новые:'}
            content={activeContent}
            setChatId={(chat) => onChatClick(chat)}
            count={count}
            update={forceUpdate}
            scrollTop={dialogScrollTop}
            loading={dialogLoading}
          />
          <Dialogs
            header={'Мои диалоги:'}
            content={myContent}
            setChatId={(chat) => onChatClick(chat)}
            count={count}
            update={forceUpdate}
            scrollTop={dialogScrollTop}
            loading={dialogLoading}
          />
          <Dialogs
            header={'Остальные:'}
            content={processContent}
            setChatId={(chat) => onChatClick(chat)}
            count={count}
            update={forceUpdate}
            scrollTop={dialogScrollTop}
            loading={dialogLoading}
          />
        </div>

        <div className="active-dialogs__chat">
          <Chat
            content={chatContent}
            setChatId={setChatId}
            iAm={Auth.Login}
            chatId={chatId}
            count={count}
            update={forceUpdate}
          />
        </div>

        <div className="active-dialogs__user-info">
          <DialogsHeader column1={{ name: 'Пользователь' }} />
          <UserInfo
            placeholder={'ФИО'}
            value={
              chatContent?.user
                ? chatContent?.user.legal
                  ? `${chatContent.user.responsible}`
                  : `${chatContent?.user.surname} ${chatContent?.user.username} ${chatContent?.user.patronymic}`
                : ''
            }
          />
          <UserInfo
            placeholder={'Статус'}
            value={
              chatContent?.user &&
              userStatusInnerText(chatContent.user.status, userStatusOptions)
            }
          />
          <UserInfo
            placeholder={'Статус ФНС'}
            value={
              chatContent?.user &&
              userStatusInnerText(chatContent.user.taxStatus, taxStatusOptions)
            }
          />
          <UserInfo
            placeholder={'Телефон'}
            value={chatContent?.user && chatContent.user.mobile}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveDialogs;
