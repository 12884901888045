import React from 'react';
import { Grid, Input, Select } from 'semantic-ui-react';
import useFetchTable from '../../../hooks/useFetchTable';
import FiltersComponent from '../../../components/FiltersComponent';
import CustomTable from '../../../components/CustomTable/CustomTable';
import TableStats from '../../../components/TableStats';
import { BooleanCell, StatusCell } from '../../../components/CustomCell';
import UserConfirmation from '../../Cards/UserCard/UserConfirmation';
import { filterURL, url } from '../../../shared/URL';
import { taxStatusOptions, userStatusOptions } from '../../../shared/constants';

const NotConfirmedUsers = () => {
  const fetchTable = useFetchTable(url.getUsers, filterURL.notConfirmedUsers);

  return (
    <div className="page table-page">
      <h1 className="page__header">Подтверждение данных для вывода денег</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Input
                type="text"
                name="surnameWithInitials"
                placeholder="ФИО пользователя"
              />
              <Input type="number" name="mobile" placeholder="Номер телефона" />
              <Select
                placeholder="Статус ФНС"
                options={taxStatusOptions}
                name="taxStatus"
                clearable
                multiple
                defaultValue={'MANUAL_CHECK'}
                disabled={true}
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={UserConfirmation}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default NotConfirmedUsers;

const columns = [
  { Header: 'ФИО пользователя', accessor: 'surnameWithInitials' },
  { Header: 'Номер телефона', accessor: 'mobile' },
  {
    Header: 'Статус пользователя',
    accessor: 'status',
    Cell: (row) => StatusCell(row, userStatusOptions),
  },
  {
    Header: 'Блокировка пользователя',
    accessor: 'locked',
    Cell: (row) => BooleanCell(row),
  },
  {
    Header: 'Блокировка вывода бонусов',
    accessor: 'pointLocked',
    Cell: (row) => BooleanCell(row),
  },
  { Header: 'Начисленные бонусы', accessor: 'chargedBonus' },
];
