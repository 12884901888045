import React, { useEffect, useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { filterURL } from '../../shared/URL';
import * as Yup from 'yup';
import './filters.scss';

interface FiltersProps {
  applyFilters: any;
  render?: Function;
  update?: Function;
  totalElements?: number;
  children?: any;
  filters?: string;
  baseUrl?: string;
}

const FiltersComponent = ({
  applyFilters,
  update,
  render,
  totalElements,
  children,
  baseUrl,
}: FiltersProps) => {
  if (!children.map) {
    children = [children];
  }

  const [filtersUrl, setFiltersUrl] = useState<string>('');
  const [filterFormErrors, setFilterFormErrors] = useState<Object>({});
  const [fieldValues, setFieldValues] = useState<Object>({});
  const [filtersValidationSchema, setFiltersValidationSchema] = useState<Yup>(
    {}
  );

  useEffect((): void => {
    formSchema();
  }, []);

  const formSchema = (): void => {
    const schemas = {};

    children.forEach((child) =>
      child.props.type === 'range'
        ? (schemas[child.props.name] = Yup.object().shape({
            from: Yup.number()
              .min(0, 'Не может быть меньше 0.')
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
            to: Yup.number()
              .min(0, 'Не может быть меньше 0.')
              .nullable(true)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          }))
        : ''
    );
    setFiltersValidationSchema(Yup.object().shape(schemas));
  };

  const passUrl = () => {
    filtersValidationSchema
      .validate(fieldValues)
      .then(() => {
        setFilterFormErrors({});
        setFiltersUrl(formUrl);
        applyFilters(formUrl());
      })
      .catch((error) => {
        const fieldName = error.path.split('.')[0];
        setFilterFormErrors({
          ...filterFormErrors,
          [fieldName]: error.errors[0],
        });
      });
  };
  const formUrl = () => {
    const separator = (url: string): string => {
      return Boolean(url.length) ? filterURL.separator : '';
    };
    let url = '';
    for (let [name, value] of Object.entries(fieldValues)) {
      const part = formUrlPart(name, value);
      url += part + separator(part);
    }
    return url.slice(0, -1) + `${baseUrl ? separator(url) + baseUrl : ''}`;
  };

  const formUrlPart = (name, value) => {
    const emptyValue =
      value === '' ||
      value === undefined ||
      (typeof value === 'object' &&
        value.from === undefined &&
        value.to === undefined &&
        !Array.isArray(value)) ||
      value.length === 0;
    if (emptyValue) {
      return '';
    }
    switch (typeof value) {
      case 'boolean':
        return `${name}$${value}`;
      case 'object':
        if (Array.isArray(value)) {
          return `${name}$${value.join(',')}`;
        }
        return `${name}$${value.from || ''}_${value.to || ''}`;
      case 'string':
        return `${name}$${value}`;
      default:
        return '';
    }
  };

  const handleInputChange = (name, data) => {
    const { value } = data;
    setFieldValues({ ...fieldValues, [name]: value || data.checked });
  };

  return (
    <div className="filters">
      <React.Fragment>
        <Form noValidate onSubmit={passUrl}>
          {children.map((child) => {
            const checkbox = child.props.type === 'checkbox';
            return React.cloneElement(child, {
              key: child.props.name,
              error: filterFormErrors[child.props.name]
                ? {
                    content: filterFormErrors[child.props.name],
                    pointing: 'below',
                  }
                : checkbox
                ? 'false'
                : false,
              onChange: (e, data) => handleInputChange(child.props.name, data),
            });
          })}
          <Button
            type="submit"
            className="filters__button"
            labelPosition="right"
            onClick={passUrl}
            icon
          >
            Применить
            <Icon name="filter" />
          </Button>
        </Form>
        {render && render(totalElements, filtersUrl, update)}
      </React.Fragment>
    </div>
  );
};

export default FiltersComponent;
