import React from 'react';
import { Grid } from 'semantic-ui-react';
import SellersTableComponent from '../SellerTableComponent';

const SellersDetailsComponent = ({ open, id, sellersTable, update }) => {
  return (
    <Grid className="details-modal__grid" stackable>
      {open && (
        <Grid.Row className="retail-point-card__sellers-table">
          <Grid.Column width="16">
            <SellersTableComponent
              id={id}
              sellersTable={sellersTable}
              update={update}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default SellersDetailsComponent;
