import React from 'react';
import useFetchTable from '../../../hooks/useFetchTable';
import { filterURL, url } from '../../../shared/URL';
import { DateTimeCell } from '../../../components/CustomCell';
import { Grid, Input } from 'semantic-ui-react';
import TableStats from '../../../components/TableStats';
import FiltersComponent from '../../../components/FiltersComponent';
import { RangeDateInput } from '../../../components/FiltersComponent/FilterField';
import CustomTable from '../../../components/CustomTable/CustomTable';
import UnknownToolCard from '../../Cards/UnknownToolCard';

const NotConfirmedUnknownTools = () => {
  const fetchTable = useFetchTable(
    url.getSalesUnknownTools,
    `${filterURL.notKnown};${filterURL.isNotRejected}`
  );

  return (
    <div className="page table-page">
      <h1 className="page__header">Добавление информации о товаре</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <RangeDateInput name="createdTime" placeholder="Дата продажи" />
              <Input
                type="text"
                name="retailInn"
                placeholder="ИНН организации"
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={UnknownToolCard}
              // default sorted - чтобы отображалась сортировка по умолчанию
              defaultSorted={[
                {
                  id: 'createdTime',
                  desc: true,
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default NotConfirmedUnknownTools;

const columns = [
  { Header: 'Серийный номер', accessor: 'sn' },
  {
    Header: 'Дата продажи',
    accessor: 'createdTime',
    Cell: (row) => DateTimeCell(row),
  },
];
