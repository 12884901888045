export const navigation = {
  auth: '/auth',
  registration: '/auth/registration',
  requestPasswordReset: '/auth/reset',
  resetPassword: '/auth/reset/:id',
  models: '/models',
  tools: '/tools',
  sellers: '/sellers',
  buyers: '/buyers',
  users: '/users',
  admins: '/admins',
  notConfirmedUsers: '/confirm-users',
  sales: '/sales',
  payments: '/payments',
  retailPoints: '/retail-points',
  receipts: '/receipts',
  notConfirmedRP: '/confirm-retail-points',
  notConfirmedSales: '/confirm-sales',
  notConfirmedSalesUnknownTools: '/confirm-sales-unknown-tools',
  antiFraudOffenders: '/anti-fraud-offenders',
  antiFraudSettings: '/anti-fraud-settings',
  reports: '/reports',
  activeDialogs: '/activeDialogs',
  completedDialogs: '/completedDialogs',
  confirmLegalTool: '/confirm-legal-tool',
  confirmLegalSale: '/confirm-legal-sale',
  invoices: '/invoices',
};

export const url = {
  login: '/login',
  logout: '/logout',
  registration: '/registration',
  resetRequestPassword: '/admin/reset/request/',
  resetPassword: '/admin/reset/',
  checkResetCode: '/admin/reset/check/',
  getProposals: '/proposals/all',
  getAdmins: '/admin/all',
  disableAdminByEmail: '/admin/disable/',
  enableAdminByEmail: '/admin/enable/',
  registerAdmin: '/admin/registration',
  confirmProposalById: '/admin/retail/point/admin/confirm/',
  rejectProposalById: '/admin/retail/point/admin/reject/',
  changeRetailPointAdmin: '/admin/retail/point/admin/change/',
  getTools: '/tool/all',
  getToolByBuyerId: '/tool/buyer/',
  importToolToDB: '/tool/import',
  getModels: '/model/all',
  changeBonuses: '/model/bonus/change',
  editModel: '/model/edit/',
  editTool: '/tool/edit/',
  importModelToDB: '/model/import',
  updateModelToDB: '/model/update',
  getPoints: '/point/all',
  blockPoint: '/point/block/',
  unblockPoint: '/point/unblock/',
  editRetailPoint: '/point/edit/',
  getPointSellers: '/point/sellers/',
  getPointByInn: '/point/inn/',
  findPointAddress: '/point/search',
  getCurrentPointAdmin: '/point/admin/',
  getProposalsCount: '/proposals/count',
  addSellerToPoint: '/admin/seller/add',
  removeSellerFromPoint: '/admin/seller/deactivate/',
  getSaleDetail: '/sale/get/',
  getSales: '/sale/all',
  getSalesUnknownTools: '/unknown-tools/all',
  editUnknownTool: '/unknown-tools',
  cancelUnknownTool: '/unknown-tools/reject/',
  saveNewModel: '/model',
  getSalesCount: '/sale/count',
  getSalesUnknownCount: '/unknown-tools/count',
  getSellerHistoryById: '/sale/seller/',
  addItemToSale: '/sale/add/item',
  rejectSale: '/sale/fail',
  addItemToLegalSale: '/sale/approve',
  rejectLegalSale: '/sale/rejected',
  getReceipts: '/receipt/all',
  getReceiptBySaleId: '/receipt',
  getPayments: '/payment/all',
  getUsers: '/user/all',
  blockUser: '/user/block/',
  unblockUser: '/user/unblock/',
  editUser: '/user/edit/',
  editLegalUser: '/user/edit/legal/',
  taxBlockUser: '/user/tax/block/',
  taxUnblockUser: '/user/tax/unblock/',
  taxConfirmUser: '/user/tax/confirm/',
  taxRejectUser: '/user/tax/reject/',
  getUserCount: '/user/count',
  findUserByNumber: '/user/mobile/',
  pushMessage: '/user/push',
  pushForAll: '/push/for-all',
  pushMessageCsv: '/push',
  getSellers: '/user/seller/all',
  resetUserPassword: '/user/password/drop/',
  getReports: '/report',
  files: '/files/',
  chatsAnswered: '/chats/answered',
  chatsActive: '/chats/active',
  chatsProcess: '/chats/process',
  chatsActive2: '/chats/v2/active',
  chatsProcess2: '/chats/v2/process',
  chatsMessages2: '/chats/v2/messages',
  chats: '/chats',
  addBonus: '/bonus/add',
  addBonusesToList: '/bonus/import',
  getFraudOffenders: '/v2/fraud/revise',
  getFraudOffenderDetails: '/v2/fraud/log/',
  setFraudRulesForAll: '/v2/fraud/rule/update/all',
  getFraudRulesForAll: '/v2/fraud/rule/get/all',
  setFraudOffenderApprove: '/v2/fraud/revise/approve',
  setFraudOffenderBlock: '/v2/fraud/revise/block',
  unknownLegalToolsCount: '/unknown-tools/legal/count/',
  unknownLegalTools: '/unknown-tools/legal/all/',
  cancelUnknownLegalTool: '/unknown-tools/reject/',
  invoices: '/invoice/all',
};

export const filterURL = {
  sellers: 'status$SELLER',
  buyers: 'status$BUYER',
  notConfirmedSales: 'saleStatus$PENDING',
  notConfirmedRP: 'confirm$false',
  roleAdmin: 'role$ADMIN_RT',
  inactiveRP: 'active$true',
  notConfirmedUsers: 'taxStatus$MANUAL_CHECK',
  notKnown: 'isKnown$false',
  isNotRejected: 'isRejected$false',
  prefix: '?filters=',
  separator: ';',
};
