import React, { FormEvent, useState, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { client, url } from '../../../shared';
import './anti-fraud-page.scss';

enum State {
  Normal,
  Loading,
  Success,
  Error,
}

export const AntiFraudSettings = () => {
  const [sqLimit, setSqLimit] = useState('');
  const [pointsLimit, setPointsLimit] = useState('');
  const [sqLimitError, setSqLimitError] = useState(false);
  const [pointsLimitError, setPointsLimitError] = useState(false);

  const [state, setState] = useState(State.Normal);
  const [details, setDetails] = useState({});
  const [currentSettings, setCurrentSettings] = useState<any>({});

  useEffect(() => {
    client
      .request({
        url: url.getFraudRulesForAll,
        method: 'get',
      })
      .then((response) => {
        setCurrentSettings(response?.data?.payload?.data);
        console.log(response?.data?.payload?.data);
      });
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state === State.Error || state === State.Success) {
      setState(State.Normal);
      return;
    }

    setState(State.Loading);

    if (!sqLimit || !pointsLimit) {
      setState(State.Normal);

      if (!sqLimit) {
        setSqLimitError(true);
      }

      if (!pointsLimit) {
        setPointsLimitError(true);
      }

      return;
    }

    let urlencoded = new URLSearchParams();
    urlencoded.append('bonus', pointsLimit);
    urlencoded.append('seqSn', sqLimit);

    client
      .request({
        url: url.setFraudRulesForAll,
        method: 'post',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        data: urlencoded,
      })
      .then((response) => {
        setState(State.Success);
        setDetails(response.data);
      })
      .catch((error) => {
        setState(State.Error);
        setDetails(error);
      });
  };

  return (
    <div className="page page-anti-fraud-settings">
      <h1 className="page__header">
        {
          {
            [State.Normal]: 'Настройки',
            [State.Loading]: 'Настройки',
            [State.Error]: 'Ошибка!',
            [State.Success]: (details as any).message,
          }[state]
        }
      </h1>
      <Form onSubmit={handleSubmit}>
        {state === State.Success ? (
          <>
            <p>
              Лимит на количество зарегистрированных подряд серийных номеров:{' '}
              {(details as any)?.payload?.data?.seqSn}
            </p>
            <p>
              Лимит на начисление баллов:
              {' ' + (details as any)?.payload?.data?.bonus}
            </p>
            <p>{(details as any)?.payload?.message}</p>
          </>
        ) : state === State.Error ? (
          <p>{(details as any)?.message}</p>
        ) : (
          <>
            <Form.Field error={sqLimitError}>
              <label>
                Количество зарегистрированных подряд серийных номеров
              </label>
              <input
                type="number"
                value={sqLimit}
                disabled={state === State.Loading}
                placeholder={currentSettings?.seqSn}
                onChange={(event) => {
                  setSqLimit(event.target.value);
                  setSqLimitError(false);
                }}
              />
              {sqLimitError && (
                <div className="ui pointing red basic label">
                  Пожалуйста, укажите лимит на количество зарегистрированных
                  подряд серийных номеров
                </div>
              )}
            </Form.Field>
            <Form.Field error={pointsLimitError}>
              <label>Лимит на начисление баллов</label>
              <input
                type="number"
                value={pointsLimit}
                disabled={state === State.Loading}
                placeholder={currentSettings?.bonus}
                onChange={(event) => {
                  setPointsLimit(event.target.value);
                  setPointsLimitError(false);
                }}
              />
              {pointsLimitError && (
                <div className="ui pointing red basic label">
                  Пожалуйста, укажите лимит на начисление баллов
                </div>
              )}
            </Form.Field>
          </>
        )}

        <Button type="submit" disabled={state === State.Loading}>
          {
            {
              [State.Normal]: 'Применить',
              [State.Loading]: 'Подождите...',
              [State.Error]: 'Попробовать снова',
              [State.Success]: 'Задать другие лимиты',
            }[state]
          }
        </Button>
      </Form>
    </div>
  );
};

export default AntiFraudSettings;
