export const RUSSIAN_PHONE_REGEX =
  /^(\+7|7|8)?[\s-]?\(?[0-9][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

export const validationErrorMessages = {
  required: 'Поле не может быть пустым',
  bonusesCantBeNegative: 'Число бонусов не может быть отрицательным',
  numberCantBeNegative: 'Число не может быть отрицательным',
  pointAdminPercentIsNumber: 'Процент АРТ должен быть числом',
  pointAdminPercentMin: 'Процент АРТ не может быть меньше 0',
  pointAdminPercentMax: 'Процент АРТ не может быть больше 110',
  mobileFormat: 'Номер должен быть в формате +7 999 111-11-11',
};

export const notificationMessages = {
  retailPointConfirmMessage: 'Торговая точка успешно подтверждена',
  noMoreRetailPointsToConfirm:
    'Розничных точек для подтверждения не осталось. Окно будет закрыто через 3 секунды',
};

export const taxStatusOptions = [
  {
    key: 'accepted',
    text: 'ИНН проверен',
    value: 'ACCEPTED',
    name: 'taxStatus',
  },
  {
    key: 'rejected',
    text: 'ИНН отклонен',
    value: 'REJECTED',
    name: 'taxStatus',
  },
  {
    key: 'awaitingCheck',
    text: 'Ожидаем проверки ИНН',
    value: 'AWAITING_CHECK',
    name: 'taxStatus',
  },
  {
    key: 'manualCheck',
    text: 'Требуется ручная проверка ИНН',
    value: 'MANUAL_CHECK',
    name: 'taxStatus',
  },
  {
    key: 'notChecked',
    text: 'ИНН не проверен',
    value: 'NOT_CHECKED',
    name: 'taxStatus',
  },
];

export const userStatusOptions = [
  { key: 'seller', text: 'Продавец', value: 'SELLER' },
  { key: 'buyer', text: 'Покупатель', value: 'BUYER' },
  {
    key: 'sellerPending',
    text: 'Продавец ожидает подтверждения',
    value: 'SELLER_PENDING',
  },
  {
    key: 'artPending',
    text: 'АРТ ожидает подтверждения',
    value: 'ART_PENDING',
  },
];

export const legalStructureOptions = [
  { key: 'legal', text: 'Все', name: 'legal' },
  { key: 'legal', text: 'Физическое лицо', value: 'false', name: 'legal' },
  { key: 'legal', text: 'Юридическое лицо', value: 'true', name: 'legal' },
];

export const receiptStatusOptions = [
  {
    key: 'fail',
    text: 'Чек не прошел проверку',
    value: 'FAIL',
    name: 'receiptStatus',
  },
  {
    key: 'taxPending',
    text: 'Ожидаем данные из ФНС',
    value: 'TAX_PENDING',
    name: 'receiptStatus',
  },
  {
    key: 'success',
    text: 'Данные из ФНС получены',
    value: 'SUCCESS',
    name: 'receiptStatus',
  },
];

export const saleStatusOptions = [
  {
    key: 'reserved',
    text: 'Зарезервировано продавцом',
    value: 'RESERVED',
    name: 'status',
  },
  {
    key: 'finish',
    text: 'Успешная продажа',
    value: 'FINISH',
    name: 'status',
  },
  { key: 'fail', text: 'Неуспешная продажа', value: 'FAIL', name: 'status' },
  {
    key: 'pending',
    text: 'Ожидается подтверждение',
    value: 'PENDING',
    name: 'status',
  },
  {
    key: 'approvePending',
    text: 'На ручной проверке',
    value: 'APPROVE_PENDING',
    name: 'status',
  },
  {
    key: 'taxPending',
    text: 'Ожидается проверка чека в фнс',
    value: 'TAX_PENDING',
    name: 'status',
  },
];

export const paymentStatusOptions = [
  { key: 'done', text: 'Успешно', value: 'DONE', name: 'status' },
  { key: 'fail', text: 'Неуспешно', value: 'FAIL', name: 'status' },
];

export const userRoleOptions = [
  { key: 'adminRT', text: 'АРТ', value: 'ADMIN_RT', name: 'sellerRole' },
  { key: 'seller', text: 'Продавец', value: 'SELLER', name: 'sellerRole' },
];
