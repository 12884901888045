import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Loader } from 'semantic-ui-react';
import { client, url } from '../../../shared';

import '../details-page.scss';

enum State {
  Normal,
  Loading,
  Success,
  Error,
}

function formatDateTime(dateTimeString: string): string {
  return new Date(Date.parse(dateTimeString))
    .toLocaleString('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
    .replace(' ', '\u00A0')
    .replace(',', '');
}

const OffensesTable = ({ offenseDetails, saleDetails, causesBySale }) => {
  return (
    <table className="ui celled table offense-modal-table">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Начисленные баллы</th>
          <th>Инструмент</th>
          <th>Серийный номер</th>
          <th>id продажи</th>
          <th>Итоговая сумма</th>
          <th>Причина блокировки</th>
        </tr>
      </thead>
      <tbody>
        {!offenseDetails.payload?.data ? (
          <tr>
            <td>"Загрузка..."</td>
          </tr>
        ) : (
          offenseDetails.payload.data.map((offense: any) => {
            let sale = saleDetails[offense.sale_id];
            let receipt = sale?.receipt;
            let tool = sale?.tool;
            return (
              <tr key={offense.sale_id}>
                <td>{formatDateTime(offense.created_time)}</td>
                <td>{sale?.sellerBonuses || 0}</td>
                <td>{tool?.model?.name}</td>
                <td>{tool?.sn}</td>
                <td>{offense.sale_id}</td>
                <td>{receipt?.totalSum}</td>
                <td>
                  {causesBySale[offense.sale_id] === 'Серия sn'
                    ? 'Подбор серийного номера'
                    : causesBySale[offense.sale_id]}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

const AntiFraudCard = ({ content, close, update }) => {
  const [open] = useState(true);
  const [state, setState] = useState(State.Loading);
  const [details, setDetails] = useState<any>({});
  const [sales, setSales] = useState<any>({});
  const [causes, setCauses] = useState<any>({});

  const approve = useCallback(() => {
    setState(State.Loading);
    client
      .request({
        url: url.setFraudOffenderApprove,
        method: 'post',
        data: new URLSearchParams(`mobile=${content.mobile}`),
      })
      .then(() => {
        close();
        update();
      });
  }, [content.mobile, close, update]);

  const block = useCallback(() => {
    setState(State.Loading);
    client
      .request({
        url: url.setFraudOffenderBlock,
        method: 'post',
        data: new URLSearchParams(`mobile=${content.mobile}`),
      })
      .then(() => {
        close();
        update();
      });
  }, [content.mobile, close, update]);

  useEffect(() => {
    let ignore = false;

    client
      .request({
        url: url.getFraudOffenderDetails + content?.mobile,
        method: 'get',
      })
      .then((response) => {
        if (ignore) {
          return;
        }

        if (response.data.payload?.data != null) {
          let causeBySale: any = {};

          response.data.payload.data.map((offense: any) => {
            causeBySale[offense['sale_id']] = offense.cause;

            client
              .request({
                url: url.getSaleDetail + offense['sale_id'],
                method: 'get',
              })
              .then((response) => {
                if (ignore) {
                  return;
                }
                setState(State.Success);
                setSales((sales: any) => {
                  let sale = response.data.payload;
                  return {
                    [sale.id]: sale,
                    ...sales,
                  };
                });
              });
          });
          setCauses(causeBySale);
        }
        setDetails(response.data);
      })
      .catch((error) => {
        if (ignore) {
          return;
        }
        setState(State.Error);
        setDetails(error);
      });

    return () => {
      ignore = true;
    };
  }, [content]);

  return (
    <Modal open={open} onClose={close} closeIcon className="details-modal">
      <Modal.Header>Нарушитель</Modal.Header>
      <Modal.Content className="details-modal__content">
        {state === State.Loading ? <Loader /> : ''}

        <div className="ui stackable grid details-modal__grid">
          <div className="row details-modal__field">
            <div className="six wide column">ФИО пользователя</div>
            <div className="ten wide column">{content.fio}</div>
          </div>
          <div className="row details-modal__field">
            <div className="six wide column">Номер телефона</div>
            <div className="ten wide column">+{content.mobile}</div>
          </div>
          <div className="row details-modal__field">
            <div className="six wide column">ИНН организации</div>
            <div className="ten wide column">{content.retail_inn}</div>
          </div>
          {/* <div className="row details-modal__field">
                        <div className="six wide column">Дата нарушения</div>
                        <div className="ten wide column">{"..."}</div>
                    </div> */}
          <div className="row details-modal__field">
            <div className="six wide column">Статус пользователя</div>
            <div className="ten wide column">
              {content.statusMessage === 'Серия sn'
                ? 'Подбор серийного номера'
                : content.statusMessage}
            </div>
          </div>
          <div className="row details-modal__field">
            <div className="sixteen wide column">
              <h3>
                {details.payload?.data && details.payload.data.length > 1
                  ? 'Нарушения'
                  : 'Нарушение'}
              </h3>
              <OffensesTable
                causesBySale={causes}
                offenseDetails={details}
                saleDetails={sales}
              />
            </div>
          </div>
          <div className="row details-modal__field">
            <div className="eight wide column">
              <button
                onClick={block}
                type="button"
                className="ui negative button"
              >
                Заблокировать
              </button>
            </div>
            <div className="eight wide column ">
              <button
                onClick={approve}
                type="button"
                className="ui positive right floated button button_positive"
              >
                Разрешить операции
              </button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AntiFraudCard;
