import React from 'react';

const UserInfo = ({ placeholder, value }) => {
  return (
    <div className="user-info">
      <div style={{ width: '100%' }}>{placeholder}</div>
      <div style={{ width: '100%' }}>{value}</div>
    </div>
  );
};

export default UserInfo;
