import React, { useEffect, useState } from 'react';
import './dialogs.scss';
import { Simulate } from 'react-dom/test-utils';
import load = Simulate.load;

interface DialogsProps {
  header: string;
  content?: any;
  setChatId: Function;
  count: number;
  update: Function;
  scrollTop: Function;
  loading?: boolean;
}

const QuantityMessages = ({ chat }) => {
  const [quantity, setQuantity] = useState<number | string>('');
  useEffect(() => {
    const result = chat.messages.filter((message) => !message.isRead);
    setQuantity(result.length);
  }, []);
  return <div className="dialogs__item__header--quantity">{quantity}</div>;
};

const Dialogs = ({
  header,
  content,
  setChatId,
  scrollTop,
  loading,
}: DialogsProps) => {
  const openChat = (chat) => {
    setChatId(chat);
  };

  const handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop + 152 > element.scrollHeight) {
      scrollTop();
    }
  };

  return (
    <div className="dialogs">
      <div className="dialogs__header">{header}</div>
      <div className="dialogs__items" onScroll={handleScroll}>
        {loading && (
          <div className="loading">
            <div className="loading__label">Загрузка...</div>
          </div>
        )}

        {content &&
          !loading &&
          content.map((chat) => {
            return (
              <div
                className="dialogs__item"
                key={chat.id}
                onClick={() => openChat(chat)}
              >
                <div className="dialogs__item__header">
                  <div className="dialogs__item__header--name">
                    {chat.user.legal
                      ? `${chat.user.responsible}`
                      : `${chat.user.surname} ${chat.user.username} ${chat.user.patronymic}`}
                  </div>
                  {/*<QuantityMessages chat={chat} />*/}
                </div>
                <div className="dialogs__item__message">
                  {chat.messages[0].message}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Dialogs;
