import React, { useState } from 'react';
import { Select, Button, Header, Form } from 'semantic-ui-react';

import { RangeDateInput } from '../../components/FiltersComponent/FilterField';
import { client } from '../../shared/client';
import { url } from '../../shared/URL';
import { downloadBlob, getFileNameFromHeader } from '../../shared/utils';
import { format } from 'date-fns';

import './reports.scss';

type ReportOption =
  | ''
  | 'SALE'
  | 'USER'
  | 'CHARGE'
  | 'DISCHARGE'
  | 'SUPPORT'
  | 'CONSOLIDATED'
  | undefined;

const ReportsPage = () => {
  const [reportType, setReportType] = useState<ReportOption>();
  const [period, setPeriod] = useState<{ from: string; to: string }>();
  const [loading, setLoading] = useState(false);

  const _getReports = () => {
    setLoading(true);
    const dateFilter = getDateFilter();
    client
      .get(`${url.getReports}/?type=${reportType}${dateFilter}`)
      .then((response) => {
        const fileName = getFileNameFromHeader(
          response.headers['content-disposition'],
          'report.csv'
        );
        const blob = new Blob([response.data]);
        downloadBlob(blob, fileName);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const getDateFilter = (): string => {
    // если не указана вторая дата в периоде (to), отправить сегодняшний день
    const to = (period && period.to) || format(new Date(), 'yyyy-MM-dd');
    const from = period && period.from;
    const prefix = 'createdTime';
    return period && from ? `&filters=${prefix}$${from}_${to}` : ``;
  };

  return (
    <div className="page">
      <div className="reports">
        <div className="reports__filters filters">
          <Header as="h1" className="reports__header">
            Отчеты
          </Header>
          <Form>
            <Select
              onChange={({}, data) => setReportType(data.value as ReportOption)}
              options={options}
              placeholder="Выберите тип отчета"
            />
            <RangeDateInput
              onChange={({}, { value }) => setPeriod(value)}
              name="reportTime"
            />
            <Button
              onClick={_getReports}
              loading={loading}
              disabled={!reportType}
            >
              Выгрузить
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

const options = [
  {
    key: 'sale',
    value: 'SALE',
    text: 'Отчет по продажам',
  },
  {
    key: 'user',
    value: 'USER',
    text: 'Отчет по пользователям',
  },
  {
    key: 'charge',
    value: 'CHARGE',
    text: 'Отчет по начислению бонусов',
  },
  {
    key: 'discharge',
    value: 'DISCHARGE',
    text: 'Отчет по выводу вознаграждений',
  },
  {
    key: 'support',
    value: 'SUPPORT',
    text: 'Отчет по поддержке',
  },
  {
    key: 'consolidated',
    value: 'CONSOLIDATED',
    text: 'Сводный отчет',
  },
  {
    key: 'retailPoint',
    value: 'RETAIL_POINT',
    text: 'Отчет по РТ',
  },
];

export default ReportsPage;
