import React, { useState } from 'react';
import { Button, Form, Grid, Icon, Modal, TextArea } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DetailsFieldComponent from '../DetailsFieldComponent';
import SuccessDataSaveNotify from '../SuccessDataSaveNotify';
import { validationErrorMessages } from '../../../shared/constants';
import { client } from '../../../shared/client';
import { url as apiUrl } from '../../../shared/URL';
import { getModifiedValues } from '../../../shared/utils';
import '../details-page.scss';

const ModelCard = (props) => {
  const { content, update, close } = props;

  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: content.name,
    msrp: content.msrp,
    vendorCode: content.vendorCode,
    url: content.url,
    sellerBonus: content.sellerBonus,
    sellerLegalBonus: content.sellerLegalBonus || 0,
    buyerBonus: content.buyerBonus,
    deviationPercent: content.deviationPercent,
    sold: content.sold,
    aliases: content.aliases && content.aliases.join('\n'),
  };

  const cancelChanges = () => {
    setLoading(false);
    close();
  };

  const _save = (values) => {
    setLoading(true);
    const modifiedValues = getModifiedValues(values, initialValues);
    if (modifiedValues.hasOwnProperty('aliases')) {
      Object.assign(modifiedValues, {
        aliases: values.aliases.split('\n'),
      });
    }
    client
      .post(apiUrl.editModel + '/' + content.id, {
        ...modifiedValues,
      })
      .then(() => {
        SuccessDataSaveNotify();
        update();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  return (
    <Modal open={open} onClose={close} closeIcon className="details-modal">
      <Modal.Header>Описание модели инструмента</Modal.Header>
      <Modal.Content className="details-modal__content">
        <Formik
          initialValues={initialValues}
          onSubmit={_save}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(validationErrorMessages.required),
            msrp: Yup.string().required(validationErrorMessages.required),
            vendorCode: Yup.string().required(validationErrorMessages.required),
            sellerBonus: Yup.number()
              .required(validationErrorMessages.required)
              .min(0, validationErrorMessages.bonusesCantBeNegative),
            sellerLegalBonus: Yup.number()
              .required(validationErrorMessages.required)
              .min(0, validationErrorMessages.bonusesCantBeNegative),
            buyerBonus: Yup.number()
              .required(validationErrorMessages.required)
              .min(0, validationErrorMessages.bonusesCantBeNegative),
            aliases: Yup.string().required(validationErrorMessages.required),
          })}
          validateOnChange={false}
          key={content.id}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            initialValues,
          }: any) => {
            return (
              <Form
                onSubmit={() => handleSubmit(values, initialValues)}
                noValidate
              >
                <Grid className="details-modal__grid" stackable>
                  <DetailsFieldComponent
                    value={values.name}
                    error={errors.name}
                    onChange={handleChange}
                    name="name"
                    placeholder="Наименование инструмента"
                  />
                  <DetailsFieldComponent
                    value={values.msrp}
                    error={errors.msrp}
                    onChange={handleChange}
                    name="msrp"
                    placeholder="РРЦ"
                  />
                  <DetailsFieldComponent
                    value={values.vendorCode}
                    error={errors.vendorCode}
                    onChange={handleChange}
                    name="vendorCode"
                    placeholder="Артикул"
                  />
                  {values.url && (
                    <DetailsFieldComponent
                      value={values.url}
                      error={errors.url}
                      onChange={handleChange}
                      name="url"
                      placeholder="Ссылка на сайт"
                    >
                      <a
                        href={values.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {values.url}
                      </a>
                    </DetailsFieldComponent>
                  )}
                  <DetailsFieldComponent
                    value={values.sellerBonus}
                    error={errors.sellerBonus}
                    onChange={handleChange}
                    name="sellerBonus"
                    placeholder="Бонусы для продавца"
                  />
                  <DetailsFieldComponent
                    value={values.sellerLegalBonus}
                    error={errors.sellerLegalBonus}
                    onChange={handleChange}
                    name="sellerLegalBonus"
                    placeholder="Бонусы для юр. лица"
                  />
                  <DetailsFieldComponent
                    value={values.buyerBonus}
                    error={errors.buyerBonus}
                    onChange={handleChange}
                    name="buyerBonus"
                    placeholder="Бонусы для покупателя"
                  />
                  <DetailsFieldComponent
                    value={values.deviationPercent}
                    error={errors.deviationPercent}
                    onChange={handleChange}
                    name="deviationPercent"
                    placeholder="Допустимое отклонение, %"
                  />
                  <DetailsFieldComponent
                    value={values.sold}
                    error={errors.sold}
                    onChange={handleChange}
                    name="sold"
                    placeholder="Продажи"
                    disabled
                  />
                  <Grid.Column width="16" className="aliases">
                    <h4 className="aliases__header">Синонимы</h4>
                    <p className="aliases__help">
                      Введите синонимы через Enter
                    </p>
                    <Form.Field
                      control={TextArea}
                      name="aliases"
                      value={values.aliases}
                      error={errors.aliases}
                      onChange={handleChange}
                      className="model-details__aliases"
                    />
                  </Grid.Column>
                  <Grid.Column width="16" className="details-modal__buttons">
                    <Button
                      type="button"
                      onClick={cancelChanges}
                      labelPosition="right"
                      icon
                    >
                      Отменить
                      <Icon name="close" />
                    </Button>
                    <Button
                      type="submit"
                      className="button_positive"
                      labelPosition="right"
                      onClick={handleSubmit}
                      loading={loading}
                      icon
                    >
                      Сохранить
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default ModelCard;
