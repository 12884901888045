import { useState } from 'react';

interface CustomTableProps {
  loading?: boolean;
  content?: Object[];
  totalPages?: number;
  size?: number;
  offset?: number;
  numberOfElements?: number;
}

const useTable = (
  props: CustomTableProps = {
    loading: true,
    content: [],
    totalPages: 0,
    size: 10,
    offset: 0,
    numberOfElements: 0,
  }
) => {
  const [table, setTable] = useState(props);

  const setState = (newState: any) =>
    setTable((prevState) => ({ ...prevState, ...newState }));

  return [table, setState] as any;
};
export default useTable;
