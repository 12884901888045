import React, { useState } from 'react';
import { url } from '../../../shared/URL';
import { Grid, Button, Icon, Modal, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import useFetchTable from '../../../hooks/useFetchTable';
import SelectTable from '../../../components/CustomTable/SelectTable';
import { successNotify } from '../../../components/NotificationsEmitter';
import { BooleanCell } from '../../../components/CustomCell';
import { client } from '../../../shared/client';
import {
  RUSSIAN_PHONE_REGEX,
  validationErrorMessages,
} from '../../../shared/constants';
import './admins-page.scss';

const AddAdminCard = ({ update }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const _addAdmin = (values) => {
    const { fullName, mobile, email, password } = values;
    const body = queryString.stringify({
      email,
      password,
      fullName,
      mobile,
    });
    setLoading(true);
    client
      .post(url.registerAdmin, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        successNotify(
          'Администратор добавлен',
          `Новый администратор ${fullName} был успешно добавлен`
        );
        update();
        setOpen(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        size="mini"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnDimmerClick={false}
        closeIcon
        trigger={
          <Button
            type="submit"
            labelPosition="right"
            className="button_positive"
            onClick={() => {
              setOpen(true);
            }}
            icon
          >
            Добавить
            <Icon name="add" />
          </Button>
        }
      >
        <Modal.Header>Добавить администратора</Modal.Header>
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            password: '',
            mobile: '',
          }}
          onSubmit={_addAdmin}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required('ФИО обязательно'),
            email: Yup.string()
              .email('Введите корректный email-адрес')
              .required('Email обязателен'),
            password: Yup.string().required('Пароль обязателен'),
            mobile: Yup.string()
              .matches(RUSSIAN_PHONE_REGEX, {
                message: validationErrorMessages.mobileFormat,
              })
              .required('Номер телефона обязателен'),
          })}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => (
            <React.Fragment>
              <Modal.Content>
                <Form onSubmit={handleSubmit} noValidate>
                  <Form.Field>
                    <Form.Input
                      value={values.mobile}
                      error={errors.mobile}
                      onChange={handleChange}
                      name="mobile"
                      placeholder="Введите номер телефона"
                      className="admins-page__input"
                    />
                    <Form.Input
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                      name="email"
                      placeholder="Введите Email"
                      className="admins-page__input"
                    />
                    <Form.Input
                      value={values.fullName}
                      error={errors.fullName}
                      onChange={handleChange}
                      name="fullName"
                      placeholder="Введите ФИО"
                      className="admins-page__input"
                    />
                    <Form.Input
                      value={values.password}
                      error={errors.password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      placeholder="Введите пароль"
                      className="admins-page__input"
                    />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  type="submit"
                  labelPosition="right"
                  className="button_positive"
                  onClick={handleSubmit}
                  loading={loading}
                  icon
                >
                  Добавить
                  <Icon name="add" />
                </Button>
              </Modal.Actions>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

const AdminsPage = () => {
  const [adminEmail, setAdminEmail] = useState<string | undefined>(undefined);
  const [enableLoading, setEnableLoading] = useState<boolean>(false);
  const [disableLoading, setDisableLoading] = useState<boolean>(false);
  const fetchTable = useFetchTable(url.getAdmins);

  return (
    <div className="page table-page admins-page">
      <h1 className="page__header">Список администраторов</h1>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column width={13}>
            <SelectTable
              table={fetchTable}
              columns={columns}
              setSelectedValue={(value) => setAdminEmail(value)}
              columnNameToSelect="email"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={13} className="admins-page__actions">
            <AddAdminCard update={fetchTable._fetch} />
            <Button
              type="submit"
              labelPosition="right"
              className="button_positive"
              onClick={() => {
                adminEmail && setEnableLoading(true);
                adminEmail &&
                  client
                    .post(url.enableAdminByEmail + adminEmail)
                    .then(() => {
                      successNotify(
                        'Активация прошла успешно',
                        `Администратор с почтой ${adminEmail} успешно разблокирован`
                      );
                      fetchTable._fetch();
                    })
                    .catch(() => {})
                    .finally(() => setEnableLoading(false));
              }}
              loading={enableLoading}
              icon
            >
              Разблокировать
              <Icon name="check" />
            </Button>
            <Button
              type="submit"
              labelPosition="right"
              onClick={() => {
                adminEmail && setDisableLoading(true);
                adminEmail &&
                  client
                    .post(url.disableAdminByEmail + adminEmail)
                    .then(() => {
                      successNotify(
                        'Деактивация прошла успешно',
                        `Администратор с почтой ${adminEmail} успешно заблокирован`
                      );
                      fetchTable._fetch();
                    })
                    .catch(() => {})
                    .finally(() => setDisableLoading(false));
              }}
              loading={disableLoading}
              icon
            >
              Заблокировать
              <Icon name="ban" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AdminsPage;

const columns = [
  { Header: 'ФИО Администратора', accessor: 'fullName' },
  { Header: 'Почта', accessor: 'email' },
  { Header: 'Номер телефона', accessor: 'mobile' },
  {
    Header: 'Активен',
    accessor: 'enabled',
    Cell: (row) => BooleanCell(row),
    maxWidth: 100,
  },
];
