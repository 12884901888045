let ws;
let timer = 0;

export function connect(
  incomingMessages,
  setIncomingMessages,
  login,
  password
) {
  //Prod
  document.cookie = `X-Authorization={"Login":"${login}", "Password":"${password}"}; path=/`;
  ws = new WebSocket(
    `wss://${
      document.location.host === 'localhost:3000'
        ? 'smart-tools.test.rtln.ru'
        : document.location.host
    }/api/mct-endpoint`
  );

  //Test
  // document.cookie = `X-Authorization={"Login":"${login}", "Password":"${password}"}; path=/`
  // ws = new WebSocket(`wss://${document.location.host === "localhost:3000"
  //   ? "smart-tools.test.rtln.ru"
  //   : document.location.host}/web/mct-endpoint`);

  ws.onopen = (event) => {
    console.log('connect', event);
    timer = 0;
  };

  ws.onmessage = (event) => {
    let message = JSON.parse(event.data);
    setIncomingMessages(...incomingMessages, message);
    console.log(message);
  };

  ws.onclose = (event) => {
    if (event.wasClean) {
      console.log(
        `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
      );
    } else {
      console.log(`[close] Соединение прервано, подключение через ${timer}мс`);
      setTimeout(function () {
        connect(incomingMessages, setIncomingMessages, login, password);
      }, timer);
      timer < 10000 ? (timer += 250) : (timer = 10000);
    }
  };
}

export function sendMessage(data) {
  ws.send(JSON.stringify(data));
}
