import React, { useState, useEffect } from 'react';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import DetailsFieldComponent from '../DetailsFieldComponent';
import RetailPointMapComponent from './components/RetailPointMapComponent';
import SellersDetailsComponent from './components/SellerDetailsComponent';
import useFetchTable from '../../../hooks/useFetchTable';
import SuccessDataSaveNotify from '../SuccessDataSaveNotify';
import BlockButtonComponent from '../../../components/BlockButtonComponent';
import ChangeAdmin from './ChangeAdmin';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import {
  RUSSIAN_PHONE_REGEX,
  validationErrorMessages,
} from '../../../shared/constants';

import '../details-page.scss';
import './retail-point-card.scss';

const RetailPointCard = ({ content, update, close }) => {
  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [adminName, setAdminName] = useState(content.retailPointAdminFullName);

  const sellersTable = useFetchTable(url.getPointSellers + content.id);

  useEffect((): void => {
    setAdminName(content.retailPointAdminFullName);
  }, [content]);

  const _save = (values: FormikValues): void => {
    const {
      directorFullName,
      directorPhone,
      adminPercentOfBonus,
      inn,
      name,
      address,
    } = values;
    setLoading(true);
    client
      .post(url.editRetailPoint + content.id, {
        directorFullName,
        directorPhone,
        adminPercentOfBonus,
        inn,
        name,
        address,
      })
      .then((): void => {
        SuccessDataSaveNotify();
        update();
      })
      .catch((): void => {})
      .finally((): void => setLoading(false));
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={close}
      className="details-modal retail-point-card"
    >
      <Modal.Header>Информация о розничной точке</Modal.Header>
      <Modal.Content className="details-modal__content retail-point-card">
        <Formik
          initialValues={{
            inn: content.inn,
            name: content.name,
            directorFullName: content.directorFullName,
            directorPhone: content.directorPhone,
            address: content.address,
            retailPointCoordinates: content.retailPointCoordinates,
            confirmed: content.confirmed ? 'Есть' : 'Нет',
            locked: content.locked,
            retailPointAdminFullName: content.retailPointAdminFullName,
            chargedBonus: content.chargedBonus,
            adminPercentOfBonus:
              content.adminPercentOfBonus !== null &&
              content.adminPercentOfBonus !== undefined
                ? content.adminPercentOfBonus
                : 10,
          }}
          onSubmit={_save}
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            directorPhone: Yup.string().matches(RUSSIAN_PHONE_REGEX, {
              message: validationErrorMessages.mobileFormat,
            }),
            adminPercentOfBonus: Yup.number()
              .typeError(validationErrorMessages.pointAdminPercentIsNumber)
              .min(0, validationErrorMessages.pointAdminPercentMin)
              .max(110, validationErrorMessages.pointAdminPercentMax),
          })}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <React.Fragment>
                <Form onSubmit={handleSubmit} noValidate>
                  <Grid className="details-modal__grid" stackable>
                    <DetailsFieldComponent
                      value={values.inn}
                      error={errors.inn}
                      onChange={handleChange}
                      name="inn"
                      placeholder="ИНН организации"
                    />
                    <DetailsFieldComponent
                      value={values.name}
                      error={errors.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Название точки"
                    />
                    <DetailsFieldComponent
                      value={values.directorFullName}
                      error={errors.directorFullName}
                      onChange={handleChange}
                      name="directorFullName"
                      placeholder="ФИО руководителя"
                    />
                    <DetailsFieldComponent
                      value={values.directorPhone}
                      error={errors.directorPhone}
                      onChange={handleChange}
                      name="directorPhone"
                      placeholder="Номер телефона руководителя"
                    />
                    <DetailsFieldComponent
                      value={values.address}
                      error={errors.address}
                      onChange={handleChange}
                      name="address"
                      placeholder="Адрес"
                    />
                    <DetailsFieldComponent
                      value={values.confirmed}
                      name="confirmed"
                      placeholder="Подтверждение РТ"
                      disabled
                    />
                    <DetailsFieldComponent
                      value={values.locked}
                      error={errors.locked}
                      onChange={handleChange}
                      name="locked"
                      placeholder="Блокировка РТ"
                      renderCustomInput={() =>
                        BlockButtonComponent({
                          id: content.id,
                          blockURL: url.blockPoint,
                          unblockURL: url.unblockPoint,
                          value: values.locked,
                          update: update,
                        })
                      }
                    />
                    <DetailsFieldComponent
                      name="retailPointAdminFullName"
                      placeholder="ФИО АРТ"
                      value={adminName}
                      renderButton={() =>
                        adminName && (
                          <ChangeAdmin
                            content={content}
                            update={() => {
                              sellersTable._fetch();
                              update();
                            }}
                            setAdminName={setAdminName}
                            sellersTable={sellersTable}
                          />
                        )
                      }
                      disabled
                    />
                    <DetailsFieldComponent
                      value={values.adminPercentOfBonus}
                      error={errors.adminPercentOfBonus}
                      onChange={handleChange}
                      name="adminPercentOfBonus"
                      placeholder="Процент АРТ"
                      icon="percent"
                      type="number"
                      min={0}
                      max={110}
                    />
                    <DetailsFieldComponent
                      value={values.chargedBonus}
                      name="chargedBonus"
                      placeholder="Количество начисленных бонусов"
                      disabled
                    />
                    <Grid.Column
                      width="16"
                      className="details-modal__buttons retail-point-card__buttons"
                    >
                      <Button
                        type="submit"
                        className="button_positive"
                        labelPosition="right"
                        icon
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Сохранить
                        <Icon name="arrow right" />
                      </Button>
                    </Grid.Column>
                    <RetailPointMapComponent
                      coordinates={content.retailPointCoordinates}
                    />
                  </Grid>
                </Form>
                <SellersDetailsComponent
                  id={content.id}
                  open={open}
                  sellersTable={sellersTable}
                  update={update}
                />
              </React.Fragment>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default RetailPointCard;
