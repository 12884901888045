import React, { useState } from 'react';
import useFetchTable from '../../hooks/useFetchTable';
import { url } from '../../shared/URL';
import { DateCell } from '../../components/CustomCell';
import { Grid, Input } from 'semantic-ui-react';
import TableStats from '../../components/TableStats';
import FiltersComponent from '../../components/FiltersComponent';
import { RangeDateInput } from '../../components/FiltersComponent/FilterField';
import CustomTable from '../../components/CustomTable/CustomTable';
import InvoiceCard from '../Cards/InvoiceCard/InvoiceCard';

const InvoicesPage = () => {
  interface ISortParams {
    sortBy: string;
    sortDirection: 'desc' | 'asc';
  }

  const [sortParams, _setSortParams] = useState<ISortParams | undefined>({
    sortBy: 'dateTime',
    sortDirection: 'desc',
  });

  const columns = [
    { Header: 'Номер документа', accessor: 'upd' },
    {
      Header: 'Дата продажи',
      accessor: 'dateTime',
      Cell: (row) => DateCell(row),
    },
    { Header: 'ИНН продавца', accessor: 'sellerInn' },
    { Header: 'Сумма', accessor: 'sum' },
  ];

  const fetchTable = useFetchTable(
    url.invoices,
    undefined,
    undefined,
    sortParams
  );

  return (
    <div className="page table-page">
      <h1 className="page__header">Список накладных</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Input type="text" name="upd" placeholder="Номер документа" />
              <Input type="text" name="sellerInn" placeholder="ИНН продавца" />
              <Input type="text" name="sum" placeholder="Сумма" />
              <RangeDateInput name="dateTime" placeholder="Дата продажи" />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={12}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={InvoiceCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default InvoicesPage;
