import React, { Fragment, useState } from 'react';
import { Divider, Modal } from 'semantic-ui-react';
import './InvoiceCard.scss';
import Card from './elements/Card/Card';

const InvoiceCard = ({ content, update, close }) => {
  const [open] = useState<boolean>(true);

  const filterEmptyStrings = (arr) => {
    return arr.filter((item) => item !== '');
  };

  const [sellerImages, _setSellerImages] = useState<string[]>(
    content && content.imageUrlSeller
      ? filterEmptyStrings(content.imageUrlSeller)
      : []
  );
  const [buyerImages, _setBuyerImages] = useState<string[]>(
    content && content.imageUrlBuyer
      ? filterEmptyStrings(content.imageUrlBuyer)
      : []
  );

  return (
    <>
      <Modal open={open} onClose={close} closeIcon className="details-modal">
        <Modal.Header>{`${content.upd} от ${content.dateTime}`}</Modal.Header>
        <Modal.Content className="details-modal__content">
          <div className="invoices">
            <h3>Прикрепленные документы покупателя</h3>
            {buyerImages.length > 0 ? (
              buyerImages.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Card item={item} />
                  </Fragment>
                );
              })
            ) : (
              <h3>Нет загруженных документов</h3>
            )}
            <Divider />
            <h3>Прикрепленные документы продавца</h3>
            {sellerImages.length > 0 ? (
              sellerImages.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Card item={item} />
                  </Fragment>
                );
              })
            ) : (
              <h3>Нет загруженных документов</h3>
            )}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default InvoiceCard;
