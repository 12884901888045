import React, { useEffect, useState } from 'react';
import { Dimmer, Image, Loader, Modal } from 'semantic-ui-react';
import { client } from '../../shared/client';
import { url } from '../../shared/URL';
import './ImagesGallery.scss';

interface PropsSrc {
  imageUrl: string;
  filter: boolean;
  setShowAllImages?: any;
}

const ImagesGallery = ({ imageUrl, filter, setShowAllImages }: PropsSrc) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');

  const getImage = (imageUrl) => {
    setLoading(true);
    client
      .get(`${url.files}${imageUrl}`, { responseType: 'arraybuffer' })
      .then((res) => {
        setImage(
          `data:image/jpeg;base64,${Buffer.from(res.data, 'binary').toString(
            'base64'
          )}`
        );
      })
      .catch(() => {
        setImage('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getImage(imageUrl);
  }, [imageUrl]);

  return (
    <>
      {filter ? (
        <div className="image-trigger" onClick={() => setShowAllImages(true)}>
          {filter && (
            <div className="image-trigger__text">
              Показать предыдущие изображения
            </div>
          )}
          {image ? (
            <img style={{ opacity: '0.2' }} src={image} alt="фото" />
          ) : (
            <div className="image-trigger__text">
              {loading ? <Loader active /> : 'Файл не найден'}
            </div>
          )}
        </div>
      ) : (
        <Modal
          centered={true}
          closeIcon
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <div className="image-trigger">
              {image ? (
                <img src={image} alt="фото" />
              ) : (
                <div className="image-trigger__text">
                  {loading ? <Loader active /> : 'Файл не найден'}
                </div>
              )}
            </div>
          }
        >
          {image && (
            <Modal.Content image>
              <Dimmer
                style={{ width: '100%', margin: 'auto' }}
                active={loading}
              >
                <Loader />
              </Dimmer>
              <Image centered size="big" src={image} />
            </Modal.Content>
          )}
        </Modal>
      )}
    </>
  );
};

export default ImagesGallery;
