import React, { useState, useEffect } from 'react';
import CustomTable from './CustomTable';
import { Radio } from 'semantic-ui-react';
import './../CustomCell/custom-cell.scss';

const SelectTable = (props) => {
  const { columnNameToSelect, columns, setSelectedValue } = props;
  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  const [tableColumns, setTableColumns] = useState<any>(columns);

  const checked = (value) => {
    return value === selectedItem;
  };

  const formColumns = () => {
    const tableColumns = [...columns];
    tableColumns.push({
      Header: 'Выбрать',
      maxWidth: 100,
      id: 'radio',
      Cell: ({ original }) => {
        let columnValue = original[columnNameToSelect];
        return (
          <div className="radio-cell">
            <Radio
              checked={checked(columnValue)}
              key={original.id || columnValue}
              type="radio"
            />
          </div>
        );
      },
    });
    return tableColumns;
  };

  useEffect(() => {
    setTableColumns(formColumns());
  }, [selectedItem]);

  return (
    <CustomTable
      {...props}
      getTrProps={(state, row) => ({
        onClick: () => {
          const value = row.original[columnNameToSelect];
          setSelectedItem(value);
          setSelectedValue(value);
        },
        className: row ? 'custom-table_clickable' : '',
      })}
      columns={tableColumns}
      selectedItem={selectedItem}
    />
  );
};

export default SelectTable;
