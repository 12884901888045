import React, { useState } from 'react';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import { successNotify } from '../../../components/NotificationsEmitter';
import CardImages from './components/CardImages';
import DetailsFieldComponent from '../DetailsFieldComponent';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import { taxStatusOptions } from '../../../shared/constants';
import { getOptionText } from '../../../shared/utils';

import '../details-page.scss';

const UserConfirmation = ({ content, update, close }) => {
  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const passportImages: Array<object> =
    content.passport && content.passport.passportImages;

  const _confirmUser = () => {
    setLoading(true);
    client
      .post(url.taxConfirmUser + content.id)
      .then(() => {
        successNotify('Успешно', 'Пользователь успешно подтвержден');
        close();
        update();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const _declineUser = () => {
    setLoading(true);
    client
      .post(url.taxRejectUser + content.id)
      .then(() => {
        successNotify('Успешно', 'Пользователь успешно отклонен');
        close();
        update();
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        close();
        update();
      }}
      closeIcon
      className="details-modal"
    >
      <Modal.Header>Карточка пользователя</Modal.Header>
      <Modal.Content className="details-modal__content">
        <Formik
          initialValues={{
            surnameWithInitials: content.surnameWithInitials,
            city: content.city,
            birthday: content.birthday,
            passport: content.passport && content.passport.passportSeries,
            issuedBy: content.passport && content.passport.issuedBy,
            issuedFrom: content.passport && content.passport.issuedFrom,
            departmentCode: content.passport && content.passport.departmentCode,
            mobile: content.mobile || '',
            inn: content.inn || '',
            taxStatus: content.taxStatus || '',
            locked: content.locked || '',
            taxLocked: content.taxLocked,
            chargedBonus: content.chargedBonus,
            dischargedBonus: content.dischargedBonus,
            roles: content.roles && content.roles.join(','),
            status: content.status,
            pointInn: content.pointInn,
            address: content.address,
            salesAmount: content.salesAmount,
          }}
          onSubmit={() => {}}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Grid className="details-modal__grid" stackable>
                  <DetailsFieldComponent
                    value={values.surnameWithInitials}
                    error={errors.surnameWithInitials}
                    onChange={handleChange}
                    name="surnameWithInitials"
                    placeholder="ФИО пользователя"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.city}
                    error={errors.city}
                    onChange={handleChange}
                    name="city"
                    placeholder="Город проживания"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.birthday}
                    error={errors.birthday}
                    onChange={handleChange}
                    name="birthday"
                    placeholder="Дата рождения"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.passport}
                    error={errors.passport}
                    onChange={handleChange}
                    name="passport"
                    placeholder="Серия/номер паспорта"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.issuedBy}
                    error={errors.issuedBy}
                    onChange={handleChange}
                    name="issuedBy"
                    placeholder="Выдан"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.issuedFrom}
                    error={errors.issuedFrom}
                    onChange={handleChange}
                    name="issuedFrom"
                    placeholder="Дата выдачи"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.departmentCode}
                    error={errors.departmentCode}
                    onChange={handleChange}
                    name="departmentCode"
                    placeholder="Код подразделения"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.mobile}
                    error={errors.mobile}
                    onChange={handleChange}
                    name="mobile"
                    placeholder="Номер телефона"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.inn}
                    error={errors.inn}
                    onChange={handleChange}
                    name="inn"
                    placeholder="ИНН пользователя"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={getOptionText(values.taxStatus, taxStatusOptions)}
                    error={errors.taxStatus}
                    onChange={handleChange}
                    name="taxStatus"
                    placeholder="Статус ФНС"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.chargedBonus}
                    error={errors.chargedBonus}
                    onChange={handleChange}
                    name="chargedBonus"
                    placeholder="Количество начисленных бонусов"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.dischargedBonus}
                    error={errors.dischargedBonus}
                    onChange={handleChange}
                    name="dischargedBonus"
                    placeholder="Количество выведенных бонусов"
                    disabled
                  />
                  <CardImages
                    passportImages={passportImages.reverse()}
                    loading={passportImages.length > 0}
                  />
                  <Grid.Column width="16" className="details-modal__buttons">
                    <Button
                      type="button"
                      labelPosition="right"
                      icon
                      onClick={_declineUser}
                      loading={loading}
                    >
                      Отклонить
                      <Icon name="ban" />
                    </Button>
                    <Button
                      type="button"
                      className="button_positive"
                      labelPosition="right"
                      icon
                      onClick={_confirmUser}
                      loading={loading}
                    >
                      Подтвердить вручную
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default UserConfirmation;
