import React from 'react';
import { url } from '../../../shared/URL';
import { Grid, Select } from 'semantic-ui-react';
import AntiFraudCard from '../../Cards/AntiFraudCard';
import CustomTable from '../../../components/CustomTable/CustomTable';
import FiltersComponent from '../../../components/FiltersComponent';
import useFetchTable from '../../../hooks/useFetchTable';

export const AntiFraudOffenders = () => {
  const fetchTable = useFetchTable(url.getFraudOffenders, null, true);

  const causeOptions = [
    {
      key: 'sn',
      text: 'Подбор серийного номера',
      value: 'sn',
      name: 'cause',
    },
    {
      key: 'date',
      text: 'Несоответствие даты чека',
      value: 'date',
      name: 'cause',
    },
    {
      key: 'limit',
      text: 'Превышен лимит начислений',
      value: 'limit',
      name: 'cause',
    },
  ];

  return (
    <div className="page table-page fraud-offenders-page">
      <h1 className="page__header">Потенциальные нарушители</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Select
                placeholder="Причина блокировки"
                options={causeOptions}
                name="cause"
                clearable
                multiple
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={AntiFraudCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AntiFraudOffenders;

const columns = [
  // { Header: "ID пользователя", accessor: "userId"},
  { Header: 'ФИО пользователя', accessor: 'fio' },
  {
    Header: 'Номер телефона',
    accessor: 'mobile',
    Cell: (row: any) => `+${row.value}`,
  },
  { Header: 'ИНН организации', accessor: 'retail_inn' },
  // {
  //     Header: "Дата нарушения",
  //     accessor: "date"
  // },
  {
    Header: 'Причина блокировки',
    accessor: 'statusMessage',
    Cell: (row: any) =>
      `${row.value === 'Серия sn' ? 'Подбор серийного номера' : row.value}`,
  },
];
