import React, { useState } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import FiltersComponent from '../../components/FiltersComponent';
import TableStats from '../../components/TableStats';
import { RangeDateInput } from '../../components/FiltersComponent/FilterField';
import useFetchTable from '../../hooks/useFetchTable';
import { filterURL, url } from '../../shared/URL';
import CustomTable from '../../components/CustomTable/CustomTable';
import { DateTimeCell } from '../../components/CustomCell';
import UnknownLegalToolCard from '../Cards/UnknownLegalToolCard/UnknownLegalToolCard';

const ConfirmLegalToolPage = () => {
  interface ISortParams {
    sortBy: string;
    sortDirection: 'desc' | 'asc';
  }

  const [sortParams, _setSortParams] = useState<ISortParams | undefined>({
    sortBy: 'createdTime',
    sortDirection: 'desc',
  });

  const columns = [
    { Header: 'Серийный номер', accessor: 'sn' },
    {
      Header: 'Дата покупки',
      accessor: 'createdTime',
      Cell: (row) => DateTimeCell(row),
    },
    { Header: 'Номер документа', accessor: 'invoice.upd', id: 'upd' },
    { Header: 'ИНН покупателя', accessor: 'initiatorInn', id: 'initiatorInn' },
    { Header: 'ИНН продавца', accessor: 'invoice.sellerInn', id: 'sellerInn' },
  ];

  const fetchTable = useFetchTable(
    url.unknownLegalTools,
    `${filterURL.notKnown};${filterURL.isNotRejected}`,
    undefined,
    sortParams
  );

  return (
    <div className="page table-page">
      <h1 className="page__header">Подтверждение инструмента</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Input type="text" name="sn" placeholder="Серийный номер" />
              <Input type="text" name="upd" placeholder="Номер документа" />
              <Input type="text" name="buyerInn" placeholder="ИНН покупателя" />
              <Input type="text" name="sellerInn" placeholder="ИНН продавца" />
              <RangeDateInput name="createdTime" placeholder="Дата покупки" />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={12}>
            <CustomTable
              table={fetchTable}
              columns={columns}
              CardComponent={UnknownLegalToolCard}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ConfirmLegalToolPage;
