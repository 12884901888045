import React, { useState, useEffect } from 'react';
import { Grid, Input, Select } from 'semantic-ui-react';
import FiltersComponent from '../../../components/FiltersComponent';
import CustomTable from '../../../components/CustomTable/CustomTable';
import TableStats from '../../../components/TableStats';
import { DateTimeCell, StatusCell } from '../../../components/CustomCell';
import UserCard from '../../Cards/UserCard';
import useFetchTable from '../../../hooks/useFetchTable';
import {
  RangeDateInput,
  RangeInput,
} from '../../../components/FiltersComponent/FilterField';
import { url } from '../../../shared/URL';
import { client } from '../../../shared/client';
import { paymentStatusOptions } from '../../../shared/constants';

const PaymentsPage = () => {
  const fetchTable = useFetchTable(url.getPayments);
  const [userCardContent, setUserCardContent] = useState<any>(undefined);

  const _getUserData = (mobile) => {
    client
      .get(url.findUserByNumber + mobile)
      .then((response) => setUserCardContent(response.data.payload))
      .catch(() => {});
  };

  useEffect(() => {
    //preload user data from first row
    fetchTable.table.content.length &&
      _getUserData(fetchTable.table.content[0].mobile);
  }, [fetchTable.table.content]);

  const columns = [
    { Header: 'ФИО пользователя', accessor: 'surnameWithInitials' },
    { Header: 'Номер телефона', accessor: 'mobile' },
    { Header: 'Количество бонусов на вывод', accessor: 'sum' },
    {
      Header: 'Дата вывода',
      accessor: 'createdTime',
      Cell: (row) => DateTimeCell(row),
    },
    {
      Header: 'Статус вывода',
      accessor: 'paymentStatus',
      Cell: (row) => StatusCell(row, paymentStatusOptions),
    },
  ];

  return (
    <div className="page table-page">
      <h1 className="page__header">Список заявок на вывод</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className="table-page__stats">
            <TableStats totalElements={fetchTable.totalElements} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <FiltersComponent
              applyFilters={fetchTable._apply}
              totalElements={fetchTable.totalElements}
            >
              <Input
                type="text"
                name="surnameWithInitials"
                placeholder="ФИО пользователя"
              />
              <Input type="number" name="mobile" placeholder="Номер телефона" />
              <RangeInput
                type="range"
                name="sum"
                placeholder="Количество бонусов на вывод"
              />
              <RangeDateInput name="createdTime" placeholder="Дата вывода" />
              <Select
                placeholder="Статус вывода"
                name="paymentStatus"
                options={paymentStatusOptions}
                clearable
                multiple
              />
            </FiltersComponent>
          </Grid.Column>
          <Grid.Column width={13}>
            {fetchTable.table.content && (
              <CustomTable
                table={fetchTable}
                columns={columns}
                CardComponent={UserCard}
                cardContent={userCardContent}
                getTrGroupProps={(state, row) =>
                  row && {
                    onClick: () => {
                      _getUserData(row.original.mobile);
                    },
                  }
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PaymentsPage;
