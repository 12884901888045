import React, { useState } from 'react';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import DetailsFieldComponent from '../DetailsFieldComponent';
import { successNotify } from '../../../components/NotificationsEmitter';
import '../details-page.scss';
import './confirm-sale.scss';

const AddBonuses = ({ update, sellId }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loadingAddToPoint, setLoadingAddToPoint] = useState<boolean>(false);

  const cancelChanges = () => {
    setOpen(false);
  };

  const _save = (values) => {
    const { seller, art, buyer } = values;
    setLoadingAddToPoint(true);
    // todo: no need to pass username/patronymic/surname if they weren't changed
    client
      .post(url.addBonus, {
        saleId: sellId,
        sellerBonus: Number(seller) || 0,
        buyerBonus: Number(buyer) || 0,
        artBonus: Number(art) || 0,
      })
      .then(() => {
        successNotify('Успешно', 'Бонусы начислены');
        update();
        setOpen(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingAddToPoint(false);
      });
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={cancelChanges}
      trigger={
        <Button
          type="button"
          icon
          onClick={() => setOpen(true)}
          labelPosition="right"
          className="button_positive"
        >
          Начислить бонусы
          <Icon name="add" />
        </Button>
      }
      size="tiny"
      className="details-modal add-bonuses"
    >
      <Modal.Header>Начисление бонусов</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            seller: '',
            art: '',
            buyer: '',
          }}
          validationSchema={Yup.object().shape({
            seller: Yup.number().typeError('Введите число'),
            art: Yup.number().typeError('Введите число'),
            buyer: Yup.number().typeError('Введите число'),
          })}
          onSubmit={_save}
          validateOnChange={false}
          enableReinitialize
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form noValidate>
                <Grid className="details-modal__grid" stackable>
                  <DetailsFieldComponent
                    value={values.seller}
                    error={errors.seller}
                    onChange={handleChange}
                    name="seller"
                    placeholder="Бонусы для Продавца"
                  />
                  <DetailsFieldComponent
                    value={values.art}
                    error={errors.art}
                    onChange={handleChange}
                    name="art"
                    placeholder="Бонусы для АРТ-а"
                  />
                  <DetailsFieldComponent
                    value={values.buyer}
                    error={errors.buyer}
                    onChange={handleChange}
                    name="buyer"
                    placeholder="Бонусы для Покупателя"
                  />
                  <Grid.Column width="16" className="details-modal__buttons">
                    <Button
                      type="button"
                      labelPosition="right"
                      icon
                      onClick={cancelChanges}
                    >
                      Отменить
                      <Icon name="close" />
                    </Button>
                    <Button
                      type="submit"
                      className="button_positive"
                      labelPosition="right"
                      icon
                      loading={loadingAddToPoint}
                      onClick={handleSubmit}
                    >
                      Начислить
                      <Icon name="arrow right" />
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default AddBonuses;
