import React, { useState } from 'react';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import { successNotify } from '../../../components/NotificationsEmitter';
import DetailsFieldComponent from '../DetailsFieldComponent';
import '../details-page.scss';
import './add-seller.scss';

const RemoveSeller = ({ retailPointId, update }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(false);
  const [loadingRemoveFromPoint, setLoadingRemoveFromPoint] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [fullName, setFullName] = useState<any>({
    username: '',
    patronymic: '',
    surname: '',
  });

  const cancelChanges = () => {
    setUserId(undefined);
    setOpen(false);
  };

  const reset = () => {
    setUserId(undefined);
    setFullName({
      username: '',
      patronymic: '',
      surname: '',
    });
  };

  const _getUser = ({ phone }) => {
    reset();
    setLoadingUserData(true);
    client
      .get(url.findUserByNumber + phone)
      .then((response) => {
        setUserId(response.data.payload.id);
        setFullName({
          username: response.data.payload.username,
          patronymic: response.data.payload.patronymic,
          surname: response.data.payload.surname,
        });
      })
      .catch(() => {})
      .finally(() => setLoadingUserData(false));
  };

  const _remove = () => {
    setLoadingRemoveFromPoint(true);
    client
      .post(url.removeSellerFromPoint + retailPointId + '/' + userId)
      .then(() => {
        successNotify('Успешно', 'Продавец был успешно удален из точки');
        setOpen(false);
        update();
      })
      .catch(() => {})
      .finally(() => setLoadingRemoveFromPoint(false));
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={cancelChanges}
      trigger={
        <Button
          type="button"
          icon
          onClick={() => setOpen(true)}
          labelPosition="right"
          className="add-seller__add-button"
        >
          Удалить продавца
          <Icon name="trash" />
        </Button>
      }
      size="tiny"
      className="details-modal add-seller"
    >
      <Modal.Header>Удаление продавца</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ phone: '' }}
          validationSchema={Yup.object().shape({
            phone: Yup.string().required('Обязательное поле.'),
          })}
          onSubmit={_getUser}
          validateOnChange={true}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form
                onSubmit={handleSubmit}
                noValidate
                className="add-seller__search-form"
              >
                <Form.Input
                  value={values.phone}
                  error={errors.phone}
                  fluid
                  action={{
                    icon: 'search',
                    type: 'submit',
                    loading: loadingUserData,
                    onClick: handleSubmit,
                  }}
                  onChange={handleChange}
                  placeholder="Введите номер телефона продавца"
                  name="phone"
                />
              </Form>
            );
          }}
        </Formik>

        {userId && (
          <Grid>
            <DetailsFieldComponent
              value={fullName.surname}
              name="surname"
              placeholder="Фамилия"
              disabled
            />
            <DetailsFieldComponent
              value={fullName.username}
              name="username"
              placeholder="Имя"
              disabled
            />
            <DetailsFieldComponent
              value={fullName.patronymic}
              name="patronymic"
              placeholder="Отчество"
              disabled
            />
            <Grid.Column width="16" className="details-modal__buttons">
              <Button
                type="button"
                labelPosition="right"
                icon
                onClick={cancelChanges}
              >
                Отменить
                <Icon name="close" />
              </Button>
              <Button
                type="submit"
                className="button_positive"
                labelPosition="right"
                icon
                loading={loadingRemoveFromPoint}
                onClick={_remove}
              >
                Удалить
                <Icon name="arrow right" />
              </Button>
            </Grid.Column>
          </Grid>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default RemoveSeller;
