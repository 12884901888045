import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker, { setDefaultLocale, registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';
import './filters.scss';

registerLocale('ru', ru);
setDefaultLocale('ru');

export const RangeInput = (props) => {
  const { placeholder, onChange, name } = props;
  const [from, setFrom] = useState<string | undefined>(undefined);
  const [to, setTo] = useState<string | undefined>(undefined);
  const [values, setValues] = useState({
    from,
    to,
  });

  useEffect(() => {
    onChange(name, { value: values });
  }, [values]);

  return (
    <div className="filters__filter-range-input">
      <p>{placeholder}</p>
      <div className="filter-range-input__inputs">
        <Form.Input
          placeholder="От"
          type="number"
          name={props.name}
          error={props.error}
          onChange={(e, { value }) => {
            setFrom(value);
            setValues({ ...values, from: value || '' });
          }}
        />
        <Form.Input
          placeholder="До"
          type="number"
          name={props.name}
          error={props.error}
          onChange={(e, { value }) => {
            setTo(value);
            setValues({ ...values, to: value || '' });
          }}
        />
      </div>
    </div>
  );
};

export const RangeDateInput = (props) => {
  const { placeholder, onChange, name } = props;
  const [firstDate, setFirstDate] = useState<any>(undefined);
  const [secondDate, setSecondDate] = useState<any>(undefined);
  const [values, setValues] = useState({
    from: firstDate,
    to: secondDate,
  });

  useEffect(() => {
    onChange(name, { value: values });
  }, [values]);

  const formatDate = (d) => {
    return !d ? undefined : format(d, 'yyyy-MM-dd');
  };

  return (
    <div className="filters__filter-range-input">
      {placeholder && <p>{placeholder}</p>}
      <div className="filter-range-input__inputs filter-date-input__inputs">
        <DatePicker
          dateFormat="dd/MM/yy"
          placeholderText="От"
          className="filter-date-input__input"
          startDate={firstDate}
          endDate={secondDate}
          selectsStart
          selected={firstDate}
          onChange={(date) => {
            setValues({ ...values, from: formatDate(date) });
            setFirstDate(date);
          }}
        />
        <DatePicker
          dateFormat="dd/MM/yy"
          placeholderText="До"
          className="filter-date-input__input"
          startDate={firstDate}
          endDate={secondDate}
          selectsEnd
          selected={secondDate}
          onChange={(date) => {
            setValues({ ...values, to: formatDate(date) });
            setSecondDate(date);
          }}
        />
      </div>
    </div>
  );
};
