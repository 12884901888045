import React, { useState } from 'react';
import { Formik } from 'formik';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Form, Grid, Modal, Button, Icon, Header } from 'semantic-ui-react';

import SuccessDataSaveNotify from '../SuccessDataSaveNotify';
import DetailsFieldComponent from '../DetailsFieldComponent';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { BooleanCell } from '../../../components/CustomCell';
import { successNotify } from '../../../components/NotificationsEmitter';
import SelectTable from '../../../components/CustomTable/SelectTable';
import { saleStatusOptions } from '../../../shared/constants';
import { client } from '../../../shared/client';
import { url } from '../../../shared/URL';
import { getOptionText, formatDateTime } from '../../../shared/utils';
import AddBonuses from '../SaleCard/AddBonuses';
import '../details-page.scss';
import './LegalSaleCard.scss';

const LegalSaleCard = ({ content, close, update, table }) => {
  const [open] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const coordinates = content.coordinates && content.coordinates.split(':');

  const id = content.id;
  const tableProps = {
    table,
    data: content && content.items ? content.items : [],
    columns,
    sortable: false,
  };

  const pendingTableProps = {
    columns: pendingColumns,
    setSelectedValue: (value) => setSelectedItem(value),
    columnNameToSelect: 'itemName',
  };

  const finished = content.saleStatus === 'FINISH';
  const pending = content.saleStatus === 'PENDING';
  const fail = content.saleStatus === 'FAIL';

  const TableComponent = pending ? SelectTable : CustomTable;

  const initialValues = {
    modelName: content.modelName,
    sn: content.sn,
    saleStatus: content.saleStatus,
    failStatusReason: content.failStatusReason,
    updateTime: formatDateTime(content.updateTime),
    createdTime: formatDateTime(content.createdTime),
    finalStatusTime: formatDateTime(content.finalStatusTime),
    retailInn: content.retailInn,
    retailAddress: content.retailAddress,
    sellerFullName: content.sellerFullName,
    buyerFullName: content.buyerFullName,
    buyerBonuses: content.buyerBonuses,
    sellerBonuses: content.sellerBonuses,
    deviationPercent: content.deviationPercent,
    modelMsrp: content.modelMsrp,
    actualDeviationPercent:
      content.actualDeviationPercent && content.actualDeviationPercent !== 999
        ? content.actualDeviationPercent
        : '',
    price: content.price,
    invoice: content.invoice,
  };

  const _confirm = (): void => {
    setLoading(true);
    client
      .post(`${url.addItemToLegalSale}/${id}`, selectedItem, {
        headers: { 'Content-Type': 'text/plain' },
      })
      .then((): void => {
        SuccessDataSaveNotify();
        close();
        update();
      })
      .catch((): void => {})
      .finally((): void => setLoading(false));
  };

  const _decline = (): void => {
    setLoading(true);
    client
      .post(`${url.rejectLegalSale}/${id}`)
      .then((): void => {
        successNotify('Отклонена', 'Продажа была успешно отклонена');
        close();
        update();
      })
      .catch((): void => {})
      .finally((): void => setLoading(false));
  };

  return (
    <Modal open={open} onClose={close} closeIcon className="details-modal">
      <Modal.Header className="legal-sale-header">
        Информация о продаже
        {initialValues.invoice.sellerInn === initialValues.invoice.buyerInn && (
          <span className="same-inn-warning">
            {' ИНН покупателя и продавца совпадают'}
          </span>
        )}
      </Modal.Header>
      <Modal.Content className="details-modal__content">
        <Formik
          initialValues={initialValues}
          onSubmit={_confirm}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, handleSubmit }: any) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <Grid className="details-modal__grid" stackable>
                  <DetailsFieldComponent
                    value={values.modelName}
                    error={errors.modelName}
                    onChange={handleChange}
                    name="modelName"
                    placeholder="Наименование инструмента"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.sn}
                    error={errors.sn}
                    onChange={handleChange}
                    name="sn"
                    placeholder="Серийный номер"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={getOptionText(values.saleStatus, saleStatusOptions)}
                    error={errors.saleStatus}
                    onChange={handleChange}
                    name="saleStatus"
                    placeholder="Статус продажи"
                    disabled
                  />
                  {fail && (
                    <DetailsFieldComponent
                      value={values.failStatusReason}
                      error={errors.failStatusReason}
                      onChange={handleChange}
                      name="failStatusReason"
                      placeholder="Причина"
                      disabled
                    />
                  )}
                  <DetailsFieldComponent
                    value={values.createdTime}
                    error={errors.createdTime}
                    onChange={handleChange}
                    name="createdTime"
                    placeholder="Дата регистрации"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.finalStatusTime}
                    error={errors.finalStatusTime}
                    onChange={handleChange}
                    name="finalStatusTime"
                    placeholder="Дата присвоения финального статуса"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.updateTime}
                    error={errors.updateTime}
                    onChange={handleChange}
                    name="updateTime"
                    placeholder="Дата продажи"
                    disabled
                  />
                  {initialValues.invoice && (
                    <>
                      <DetailsFieldComponent
                        value={values.invoice.upd}
                        name="upd"
                        placeholder="Номер накладной"
                        disabled
                      />
                      <DetailsFieldComponent
                        value={values.invoice.dateTime}
                        name="dateTime"
                        placeholder="Дата продажи по накладной"
                        disabled
                      />
                    </>
                  )}
                  <DetailsFieldComponent
                    value={values.retailInn}
                    error={errors.retailInn}
                    onChange={handleChange}
                    name="retailInn"
                    placeholder="ИНН РТ"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.retailAddress}
                    error={errors.retailAddress}
                    onChange={handleChange}
                    name="retailAddress"
                    placeholder="Адрес РТ"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.sellerFullName}
                    error={errors.sellerFullName}
                    onChange={handleChange}
                    name="sellerFullName"
                    placeholder="ФИО продавца"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.buyerFullName}
                    error={errors.buyerFullName}
                    onChange={handleChange}
                    name="buyerFullName"
                    placeholder="ФИО покупателя"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.buyerBonuses}
                    error={errors.buyerBonuses}
                    onChange={handleChange}
                    name="buyerBonuses"
                    placeholder="Количество бонусов для покупателя"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.sellerBonuses}
                    error={errors.sellerBonuses}
                    onChange={handleChange}
                    name="sellerBonuses"
                    placeholder="Количество бонусов для продавца"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.modelMsrp}
                    error={errors.modelMsrp}
                    onChange={handleChange}
                    name="modelMsrp"
                    placeholder="РРЦ"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.invoice.sum}
                    error={errors.price}
                    onChange={handleChange}
                    name="sum"
                    placeholder="Цена из накладной"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.deviationPercent}
                    error={errors.deviationPercent}
                    onChange={handleChange}
                    name="deviationPercent"
                    placeholder="Допустимое отклонение, %"
                    disabled
                  />
                  <DetailsFieldComponent
                    value={values.actualDeviationPercent}
                    error={errors.actualDeviationPercent}
                    onChange={handleChange}
                    name="actualDeviationPercent"
                    placeholder="Фактическое отклонение, %"
                    disabled
                  />
                  {fail && <AddBonuses update={update} sellId={id} />}
                  {(finished || pending) && (
                    <Grid.Row className="row details-modal__field">
                      <Grid.Column width={16}>
                        <h3>Позиции в чеке</h3>
                        <TableComponent
                          {...tableProps}
                          {...(pending ? pendingTableProps : {})}
                        />
                        {pending ? (
                          <div className="details-modal__buttons">
                            <Grid.Column width={3}>
                              <Button
                                labelPosition="right"
                                icon
                                onClick={_decline}
                                loading={loading}
                                disabled={loading}
                              >
                                Отклонить
                                <Icon name="ban" />
                              </Button>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Button
                                className="button_positive"
                                labelPosition="right"
                                icon
                                onClick={_confirm}
                                loading={loading}
                                disabled={loading}
                              >
                                Подтвердить
                                <Icon name="arrow right" />
                              </Button>
                            </Grid.Column>
                          </div>
                        ) : null}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {coordinates && (
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <h3>На карте:</h3>
                        <YMaps>
                          <Map
                            defaultState={{
                              center: coordinates,
                              zoom: 9,
                              controls: ['fullscreenControl'],
                            }}
                            modules={['control.FullscreenControl']}
                            className="map"
                          >
                            <Placemark defaultGeometry={coordinates} />
                          </Map>
                        </YMaps>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
                <Grid.Column width="16" className="details-modal__buttons">
                  <Button
                    type="button"
                    onClick={_decline}
                    labelPosition="right"
                    icon
                    disabled={
                      initialValues.sellerFullName === 'N/A' ||
                      initialValues.buyerFullName === 'N/A'
                    }
                  >
                    Отменить
                    <Icon name="close" />
                  </Button>
                  <Button
                    type="submit"
                    className="button_positive"
                    labelPosition="right"
                    loading={loading}
                    icon
                  >
                    Сохранить
                    <Icon name="arrow right" />
                  </Button>
                </Grid.Column>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default LegalSaleCard;

const columns = [
  { Header: 'Наименование в чеке', accessor: 'itemName' },
  { Header: 'Цена в чеке', accessor: 'itemPrice' },
  { Header: '', accessor: 'selected', Cell: (row) => BooleanCell(row) },
];
const pendingColumns = [
  { Header: 'Наименование в чеке', accessor: 'itemName' },
  { Header: 'Цена в чеке', accessor: 'itemPrice' },
];
