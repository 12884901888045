import React, { useState, useEffect } from 'react';
import ReactFileReader from 'react-file-reader';
import { Button, Icon } from 'semantic-ui-react';
import { FormikValues } from 'formik';

import { client } from '../../shared/client';
import { url } from '../../shared/URL';
import PushMessage from '../../shared/model/push-message';
import { successNotify } from '../NotificationsEmitter';

import './csv-push-button.scss';

interface Props {
  values: FormikValues;
  onClose: Function;
}

const CsvPushButton = ({ values, onClose }: Props) => {
  const [message, setMessage] = useState<PushMessage>();
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<any>();

  const isValid = Boolean(message && message.body && message.title);

  useEffect(() => {
    setMessage({
      title: values.header,
      body: values.text,
    });
  }, [values]);

  useEffect(() => {
    files && _sendUserList();
  }, [files]);

  const formRequestFormData = () => {
    if (!message) {
      return;
    }
    const push = {
      titleType: message.title,
      body: message.body,
    };
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('push', JSON.stringify(push));
    return formData;
  };

  const _sendUserList = () => {
    setLoading(true);
    const formData = formRequestFormData();
    client
      .post(url.pushMessageCsv, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        successNotify(
          'Отправка сообщения прошла успешно',
          'Сообщение было отправлено списку пользователей'
        );
        onClose();
      })
      .catch(() => {})
      .finally(() => {
        setFiles(undefined);
        setLoading(false);
      });
  };

  return (
    <div className="csv-push-button-wrapper">
      <ReactFileReader
        handleFiles={setFiles}
        disabled={!isValid}
        fileTypes={['.csv']}
      >
        <Button
          icon
          loading={loading}
          disabled={!isValid}
          labelPosition="right"
        >
          Отправить списку пользователей
          <Icon name="file" />
        </Button>
      </ReactFileReader>
    </div>
  );
};

export default CsvPushButton;
